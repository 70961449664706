import React from 'react'
import styled from "styled-components";
import Image from '../../../../generic/assets/noData.jpg'
import { useHistory, useParams } from 'react-router-dom';
import useDataFetcher from '../knisot/hooks/useFetchData';
import { Invites } from '../../../../modules/API/invites/invites';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers/rootReducer';
import { InvitationData } from '../../../../modules/API/invites/models/invite';
import { resetState } from '../../../../../store/actions/resetState';

const CardContainer = styled.div`
  --main-color: #145a32;   
  --submain-color: #1e8449;   
  --bg-color: #ffffff;        
  position: relative;
  width: 300px;
  height: 384px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: var(--bg-color);
  filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const CardImg = styled.div`
  height: 192px;
  width: 100%;

  svg {
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
`;

const CardAvatar = styled.div`
  position: absolute;
  width: 114px;
  height: 114px;
  background: var(--bg-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 57px); 
  padding: 0.5em;

`;
const AvatarContainerImage = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
`

const CardTitle = styled.div<{alert?: boolean}>`
  margin-top: 60px; 
  font-weight: 500;
  font-size: 18px;
  color: ${props => props.alert ? 'tomato' : 'var(--main-color)'} ;
`;

const CardSubtitle = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  color: var(--submain-color);
`;

const CardWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;
`;


const CardButton = styled.button`
  width: 76px;
  height: 31px;
  border: 2px solid var(--main-color);
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.9em;
  color: var(--main-color);
  background: var(--bg-color);
  text-transform: uppercase;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: var(--main-color);
    color: var(--bg-color);
  }
`;

const CardButtonSolid = styled(CardButton)`
  background: var(--main-color);
  color: var(--bg-color);

  &:hover {
    background: var(--bg-color);
    color: var(--main-color);
  }
`;

interface CardProps {
  title?: string;
  subtitle?: string;
}

const InvitedMain = ({
  title = "NEW GROWER",
  subtitle = "CAFON",
}: CardProps) => {
  const dispatch = useDispatch()
  const {id} = useParams<{id: string}>()
  const user = useSelector((state: RootState) => state?.mainReducer?.user)
  const isAuth = !!user
  const history = useHistory()

  const {
    data: inviteData,
    isLoading,
    errorCode
  } = useDataFetcher<InvitationData>(
    Invites.getInviteDataByToken, id
  )

  const acceptFunction = async () => {
    try {
      if(!isAuth) {
        history.push(`/login?token=${id}`)
      } else if(user.internal) {
        dispatch(resetState())   
        history.push(`/login?token=${id}`)

      } else {
        await Invites.addCompany({token: id})
        history.push(`/main/azmanot`)
      }
    } catch (error) {
      console.log(error)
    } finally {}
  }

  const redirectFunction = () => {
    history.push(`/main/azmanot`)
  }
  
  return (
    <MainWrapper>
      <CardContainer>
        <CardImg>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%">
            <rect fill="#ffffff" width="540" height="450"></rect>
            <defs>
              <linearGradient
                id="a"
                gradientUnits="userSpaceOnUse"
                x1="0"
                x2="0"
                y1="0"
                y2="100%"
                gradientTransform="rotate(222,648,379)"
              >
                <stop offset="0" stopColor="#ffffff"></stop>
                <stop offset="1" stopColor="#2ecc71"></stop>
              </linearGradient>

              <pattern
                patternUnits="userSpaceOnUse"
                id="b"
                width="300"
                height="250"
                x="0"
                y="0"
                viewBox="0 0 1080 900"
              >
                <g fillOpacity="0.5">
                  <polygon fill="#145A32" points="90 150 0 300 180 300"></polygon>
                  <polygon fill="#FFFFFF" points="90 150 180 0 0 0"></polygon>
                  <polygon fill="#82E0AA" points="270 150 360 0 180 0"></polygon>
                  <polygon fill="#D1F2EB" points="450 150 360 300 540 300"></polygon>
                  <polygon fill="#58D68D" points="450 150 540 0 360 0"></polygon>
                  <polygon fill="#FFFFFF" points="630 150 540 300 720 300"></polygon>
                  <polygon fill="#D1F2EB" points="630 150 720 0 540 0"></polygon>
                  <polygon fill="#145A32" points="810 150 720 300 900 300"></polygon>
                  <polygon fill="#FFFFFF" points="810 150 900 0 720 0"></polygon>
                  <polygon fill="#D1F2EB" points="990 150 900 300 1080 300"></polygon>
                  <polygon fill="#145A32" points="990 150 1080 0 900 0"></polygon>
                  <polygon fill="#D1F2EB" points="90 450 0 600 180 600"></polygon>
                  <polygon fill="#FFFFFF" points="90 450 180 300 0 300"></polygon>
                  <polygon fill="#1E8449" points="270 450 180 600 360 600"></polygon>
                  <polygon fill="#82E0AA" points="270 450 360 300 180 300"></polygon>
                  <polygon fill="#D1F2EB" points="450 450 360 600 540 600"></polygon>
                  <polygon fill="#58D68D" points="450 450 540 300 360 300"></polygon>
                  <polygon fill="#58D68D" points="630 450 540 600 720 600"></polygon>
                  <polygon fill="#FFFFFF" points="630 450 720 300 540 300"></polygon>
                  <polygon fill="#FFFFFF" points="810 450 720 600 900 600"></polygon>
                  <polygon fill="#D1F2EB" points="810 450 900 300 720 300"></polygon>
                  <polygon fill="#82E0AA" points="990 450 900 600 1080 600"></polygon>
                  <polygon fill="#145A32" points="990 450 1080 300 900 300"></polygon>
                  <polygon fill="#0B3D02" points="90 750 0 900 180 900"></polygon>
                  <polygon fill="#FFFFFF" points="270 750 180 900 360 900"></polygon>
                  <polygon fill="#D1F2EB" points="270 750 360 600 180 600"></polygon>
                  <polygon fill="#FFFFFF" points="450 750 540 600 360 600"></polygon>
                  <polygon fill="#FFFFFF" points="630 750 540 900 720 900"></polygon>
                  <polygon fill="#145A32" points="630 750 720 600 540 600"></polygon>
                  <polygon fill="#82E0AA" points="810 750 720 900 900 900"></polygon>
                  <polygon fill="#1E8449" points="810 750 900 600 720 600"></polygon>
                  <polygon fill="#58D68D" points="990 750 900 900 1080 900"></polygon>
                  <polygon fill="#58D68D" points="180 0 90 150 270 150"></polygon>
                  <polygon fill="#145A32" points="360 0 270 150 450 150"></polygon>
                  <polygon fill="#FFFFFF" points="540 0 450 150 630 150"></polygon>
                  <polygon points="900 0 810 150 990 150"></polygon>
                  <polygon fill="#0B3D02" points="0 300 -90 450 90 450"></polygon>
                  <polygon fill="#FFFFFF" points="0 300 90 150 -90 150"></polygon>
                  <polygon fill="#FFFFFF" points="180 300 90 450 270 450"></polygon>
                  <polygon fill="#1E8449" points="180 300 270 150 90 150"></polygon>
                  <polygon fill="#0B3D02" points="360 300 270 450 450 450"></polygon>
                  <polygon fill="#FFFFFF" points="360 300 450 150 270 150"></polygon>
                  <polygon fill="#145A32" points="540 300 450 450 630 450"></polygon>
                  <polygon fill="#0B3D02" points="540 300 630 150 450 150"></polygon>
                  <polygon fill="#82E0AA" points="720 300 630 450 810 450"></polygon>
                  <polygon fill="#1E8449" points="720 300 810 150 630 150"></polygon>
                  <polygon fill="#FFFFFF" points="900 300 810 450 990 450"></polygon>
                  <polygon fill="#58D68D" points="900 300 990 150 810 150"></polygon>
                  <polygon fill="#0B3D02" points="0 600 -90 750 90 750"></polygon>
                  <polygon fill="#1E8449" points="0 600 90 450 -90 450"></polygon>
                  <polygon fill="#82E0AA" points="180 600 90 750 270 750"></polygon>
                  <polygon fill="#145A32" points="180 600 270 450 90 450"></polygon>
                  <polygon fill="#145A32" points="360 600 270 750 450 750"></polygon>
                  <polygon fill="#58D68D" points="360 600 450 450 270 450"></polygon>
                  <polygon fill="#1E8449" points="540 600 630 450 450 450"></polygon>
                  <polygon fill="#0B3D02" points="720 600 630 750 810 750"></polygon>
                  <polygon fill="#FFFFFF" points="900 600 810 750 990 750"></polygon>
                  <polygon fill="#0B3D02" points="900 600 990 450 810 450"></polygon>
                  <polygon fill="#D1F2EB" points="0 900 90 750 -90 750"></polygon>
                  <polygon fill="#145A32" points="180 900 270 750 90 750"></polygon>
                  <polygon fill="#FFFFFF" points="360 900 450 750 270 750"></polygon>
                  <polygon fill="#82E0AA" points="540 900 630 750 450 750"></polygon>
                  <polygon fill="#FFFFFF" points="720 900 810 750 630 750"></polygon>
                  <polygon fill="#0B3D02" points="900 900 990 750 810 750"></polygon>
                  <polygon fill="#0B3D02" points="1080 300 990 450 1170 450"></polygon>
                  <polygon fill="#FFFFFF" points="1080 300 1170 150 990 150"></polygon>
                  <polygon fill="#FFFFFF" points="1080 600 990 750 1170 750"></polygon>
                  <polygon fill="#1E8449" points="1080 600 1170 450 990 450"></polygon>
                  <polygon fill="#D1F2EB" points="1080 900 1170 750 990 750"></polygon>
                </g>
              </pattern>
            </defs>
            <rect x="0" y="0" fill="url(#a)" width="100%" height="100%"></rect>
            <rect x="0" y="0" fill="url(#b)" width="100%" height="100%"></rect>
          </svg>
        </CardImg>
        <CardAvatar>
          <AvatarContainerImage>
            <img
              src={Image}
              alt='avatar'
            />
          </AvatarContainerImage>
        </CardAvatar>
         {
            inviteData ?
            <>
              <CardTitle>DEAR {inviteData.Name?.trim()}</CardTitle>
              <CardSubtitle>YOU HAS BEEN INVITED TO {inviteData.CompanyName?.trim()}</CardSubtitle>
              <CardWrapper>
                <CardButton
                  onClick={acceptFunction}
                >
                  Accept
                </CardButton>
              </CardWrapper>
            </> 
            : 
            <>
              {
                errorCode === 404 ?
                <CardTitle alert>ההזמנה לא קיימת או שפג תוקפו</CardTitle>
                :
                <CardTitle alert>משהו השתבש</CardTitle>
              }
              <CardWrapper>
                <CardButton
                  onClick={redirectFunction}
                >
                  לסגור
                </CardButton>
              </CardWrapper>
            </>
         }  
      </CardContainer>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E9F0F0;
`


export default InvitedMain