import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { CLEAR_STATE_KOSHER, LOAD, LOAD_END, SELECT_CLIENT_COSHER, SELECT_DRIVER_KOSHER } from "../../../../../../store/actions/actionsTypes";
import { RootState } from "../../../../../../store/reducers/rootReducer";
import ControlButton from "../../../../../generic/buttons/ControlButton";
import DatePickerModule from "../../../../../generic/datePicker/datePicker";
import SearchInput from "../../../../../generic/inputs/inputSearch";
import OrderlikeLayout from "../../../../../generic/Layouts/OrderlikeLayout";
import ControlSearchPannelLayout from "../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout";
import TitlesList from "../../../../../generic/titlesRowItem/TitlesList";
import { IClientsItem } from "../../../../../modules/API/Defaults/models/customers";
import { Pallets } from "../../../../../modules/API/Pallets/pallets";
import PalletItem from "./PalletItem";
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList.js'
import { Certificates } from "../../../../../modules/API/Certificates/certificates";
import { Defaults } from "../../../../../modules/API/Defaults/defaults";
import { ICarItemResponse } from "../../../../../modules/API/Defaults/models/cars";
import ColumModal from "../../../../../generic/Layouts/ColumModal";
import SelectDriver from "../../trucks/SelectDriver";
import { PlusIcon, SettingMiksher } from "../../../../../generic/icons/Icons";
import { ControlPanelWrapper } from "../../secondbar/modalAzmanot/modalAzmanot";
import { IPackagesItem } from "../../../../../modules/API/Defaults/models/packages";
import { ContextModal } from "../../../../../modules/modal/modalContext";
import { LINKS } from "../../../../../Routs/config";
import ScanInput from "../Components/ScanInput";
import useScannedDataHandler from "../Hooks/useScannedDataHandler";
import CircleActiveFilterBtn from "../../../../../generic/particles/CircleActiveFilterBtn";
import AddProductModal from "../../market/Collect/Components/AddProductModal";
import { ISelectPalletProps } from "../../market/Collect/types/selectedPallet";
import useDataFetcher from "../../knisot/hooks/useFetchData";
import { ISellerResponse } from "../../../../../modules/API/Defaults/models/seller";
import { IGrowerNodeGetResponse } from "../../../../../modules/API/Defaults/models/growers";
import { IProductsWithVarietyItem } from "../../../../../modules/API/Defaults/models/compliteProducts";
import { IGetReuqestResponseNode } from "../../../../../modules/API/Defaults/models/pallets";
import selectBrodMigdal from "../../../../../generic/utils/selectBrodMigdal";
import { ISizeItem } from "../../../../../modules/API/Products/models/size";
import { Product } from "../../../../../modules/API/Products/products";
import { IReqProduct } from "../../market/Collect/MarketCollectMain";
import { IMarketPalletDetails, IPostProduct, IUpdateProduct } from "../../../../../modules/API/Market/models/marketRequests";
import { checkPackType } from "../../../../../generic/utils/checkPackType";
import { Market } from "../../../../../modules/API/Market/market";
import { format } from "date-fns";
import TabsBodyContent from "../Components/TabsBodyContent";
import useTabsTeuda from "../generic/tabList";
import { IPalletEdited } from "../types/palletItem";
import { ITeudotCreatUrlParams } from "../types/params";
import { ITeudotItem } from "../../../../../../models/RequestsModel/teudot";
import TitleTeuda from "../Components/TitleTeuda";
import { IPalletDetails } from "../../../../../modules/API/Pallets/models/palletDetails";
import SettingsModsl from "../Components/SettingsModsl";
import { ITeudotTypeItem } from "../../../../../modules/API/Defaults/models/teudotTypes";
import { useKnisaConfig } from "../../knisot/Create/config";
import { IPutItem } from "../../../../../modules/API/Certificates/models/patchItem";
import PalletsToChange from "../../../../../generic/modals/PalletsToChange";
import { PalletToChangeTypes } from "../../../../../modules/API/Pallets/models/palletToChange";
import ProductModal from "../../market/Collect/Components/ProductModal";
import { useTranslation } from "react-i18next";
import { handleNotifications } from "../../../../../generic/utils/handleNotifications";


interface IRef {
    focusInput: () => void;
    blurInput: () => void;
}
function CreateTeuda() {
    const [t] = useTranslation(["teudot", "buttons"]);
    const {TAB_LIST} = useTabsTeuda();
    const dispatch = useDispatch();
    const history = useHistory()
    const config = useKnisaConfig()
    const tranzit = useSelector((state: RootState) => state.mainReducer.user?.tranzit)
    const { id, subID } = useParams<ITeudotCreatUrlParams>();
    const titleTeuda: ITeudotItem = useSelector((teuot: RootState) => teuot.kosherTeudotReducer.title);
    const isEditMode = id !== 'new'
    
    const { defaults } = useSelector((state: RootState) => state.defaults)
    const rights = useSelector((state: RootState) => state.mainReducer.user?.rights)

    const isWorker = rights?.includes('WORKER')
    const isAllowToEdit = rights?.includes('SELLER') || rights?.includes('ADMIN') || rights?.includes('OFFICE')
    const overweightDelta = selectBrodMigdal(defaults, 629, 'Num_Value')
    const defaultPackage = selectBrodMigdal(defaults, 382, 'Num_Value')
    const defaultArizaTranzit: number = useSelector((item: RootState) => item.defaults.defaults.filter((e: { Int_ID: number }) => e.Int_ID === 5)[0]?.Num_Value);
    const defaultTeudaType = selectBrodMigdal(defaults, 179, 'Num_Value', 'יציאה')
    const defaultPalletType: number = useSelector((item: RootState) => item.defaults.defaults === null ? '' : item.defaults.defaults.filter((e: { Int_ID: number, Sub_ID: string }) => {
        return e.Int_ID === 14 && e?.Sub_ID?.trim() === '1'
    })[0]?.Num_Value)

    const defaultCreateState = useSelector(
        (state: RootState) => state.kosherTeudotReducer
    )

    const packages = useSelector(
        (packages: RootState): IPackagesItem[] => packages.defaults.packageList
    );

    const packageHash = useMemo(() =>
        packages.reduce((acc: Record<number, IPackagesItem>, cur) => {
            acc[cur.PackID] = cur
            return acc
        }, {}
    ), [packages])

    const clients = useSelector((state: RootState): IClientsItem[] => state.defaults?.clientList?.list);
    const [modal, setModal] = useContext(ContextModal)
    const [activeTab, setActiveTab] = useState<string>(isEditMode ? TAB_LIST[0] : TAB_LIST[1]);

    const [date, setDate] = useState<string | null>(null);
    const [car, setCar] = useState<number>(defaultCreateState.create?.carInfo || 0)
    const [isOpenSelectDriver, setIsOpenSelectDriver] = useState(false);
    const [isOpenPalletObject, setIsOpenPalletObject] = useState(false);
    const [isOpenPalletToChangeModal, setIsOpenPalletToChangeModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState<number | null>(isEditMode ? titleTeuda?.CustomerID : defaultCreateState?.create?.clientID || null);
    
    const [palletList, setPalletList] = useState<IPalletEdited[]>([]);
    const [palletInput, setPalletInput] = useState<string>('');

    const [tempPalletObject, setTempPalletObject] = useState<IPalletEdited | null>(null)
    const refBody = useRef<IRef | null>(null);
    const [isOpenSettings, setIsOpenSettings] = useState<boolean>(isEditMode ? false : true);
    const [sellerID, setSellerID] = useState<number>(titleTeuda?.Reseller_Code || 0);
    const [teudaType, setTeudaType] = useState<number>(defaultTeudaType);

    const [isOpenProductModal, setIsOpenProductModal] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<IPalletDetails | null>(null);
    
    const {
        data: sellerList
    } = useDataFetcher<ISellerResponse>(
        Defaults.getSellerList, 'data'
    )
    const {
        data: teudaTypeList
    } = useDataFetcher<ITeudotTypeItem[]>(
        Defaults.getTblTeudotTypes, "י"
    )
   
    const {
        data: carList
    } = useDataFetcher<ICarItemResponse>(
        Defaults.getCarList, 'data'
    )

    const getPallets = async () => {
        if (!selectedClient) return
        dispatch({ type: LOAD })
        try {
            let res = await Pallets.getPalletListWithDetails(selectedClient || titleTeuda.CustomerID);
            const resWithSelected = res.map((item: IPalletEdited) => {
                item.isSelected = false
                return item
            })

            if(isEditMode) {
                const resDetails: IPalletDetails[] = await Pallets.getPalletsDetailsByDelivery(+id, +subID);
                const hash: Record<number, IPalletEdited> = {}
                
                resDetails.forEach(e => {
                    if(!hash[e.Pallet_Num]) {
                        hash[e.Pallet_Num] = {
                            CustomerID: e.CustomerID,
                            Customer_Name: e.Customer_Name,
                            Details: [e],
                            Pallet_Date: e.Delivery_Date,
                            Pallet_Num: e.Pallet_Num,
                            isSelected: true
                        }
                    } else {
                        hash[e.Pallet_Num].Details.push(e)
                    }
                })

                setPalletList([...Object.values(hash), ...resWithSelected])
                return
            }
            setPalletList(resWithSelected)
        } catch (err) {
            console.log(err)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    const deleteProduct = async (
        pallet: number,
        recID: number,
      ) => {
     
        try {
            await Pallets.deleteProductFromPallet(recID)
            let newArr = [...palletList]
            newArr.map((item) => {
                if (item.Pallet_Num === pallet) {
                    item.Details = item.Details.filter(e => e.RecID !== recID)
                }
                return item
            })

            handleNotifications('המוצר נמחק בהצלחה', {type: 'success'})
            setPalletList(newArr)
        } catch (error: any) {
        } finally {
    
        }
      }

    const selectClient = (id: number, name: string) => {
        dispatch({ type: SELECT_CLIENT_COSHER, clientID: id, clientName: name })
        setSelectedClient(id);
       
    }

    const handleControlSettings = () => {
        setIsOpenSettings(!isOpenSettings)
    }

    const renderPalletList = useMemo(() => {
        if (!palletList) return []
        const filtered = palletList.filter(item =>
            (activeTab === TAB_LIST[0] ? item.isSelected : !item.isSelected) &&
            (
                item.Pallet_Num?.toString().includes(palletInput.trim())
                ||
                item.Details.filter(e =>
                    `${e.ProductDesc?.trim()} ${e.VarietyDesc?.trim()}`.toLocaleLowerCase().includes(palletInput)
                ).length > 0
            )
        )
        return filtered
    }, [palletList, palletInput, activeTab])

    useEffect(() => {
        if (!selectedClient) {
            setSelectedClient(null)
            setPalletList([])
        } 
    }, [selectedClient])

    useEffect(() => {
        getPallets();
    }, [selectedClient])

    const handleControlPalletToChangeModal = () => {
        if(id === 'new' || palletList.length === 0) return
        
        setIsOpenPalletToChangeModal(!isOpenPalletToChangeModal)
    }
    const handleUnboundPallet = async (id: number) => {
        if(!isEditMode || !isAllowToEdit) return
        try {
            dispatch({ type: LOAD })
            await Certificates.unboundPallet(id)
        } catch (err) {

        } finally {
            dispatch({ type: LOAD_END })
        }
    }
    const handleSelectPallet = useCallback( async (index: number, palletNum: number) => {
        if (!palletList ) return 
        let newArr = [...palletList]
        if (palletList.length === renderPalletList?.length) {
            if (newArr[index].isSelected) {
                newArr[index].isSelected = false
                await handleUnboundPallet(palletNum)
            } else {
                newArr[index].isSelected = true
            }
        } else {
            let item = newArr.find(e => e.Pallet_Num === palletNum)
            if (!item) return
            const indexOfItem = newArr.indexOf(item)
            if (newArr[indexOfItem].isSelected) {
                newArr[indexOfItem].isSelected = false
                await handleUnboundPallet(palletNum)
            } else {
                newArr[indexOfItem].isSelected = true
            }
        }
       
        setPalletList(newArr)
        setPalletInput('');

    }, [palletList, renderPalletList])

    const selectDriver = (item: number) => {
        dispatch({ type: SELECT_DRIVER_KOSHER, payload: item })
        setCar(item);
    }

    const createTeuda = async () => {
        dispatch({ type: LOAD })
        const palletsArray = palletList?.reduce((acc: number[], cur: IPalletEdited) => {
            if (cur.isSelected) {
                acc.push(cur.Pallet_Num)
            }
            return acc
        }, [])

        
        

        try {
            if (isEditMode) {
                const putData: IPutItem = {
                    PalletNums: palletsArray,
                    OrderNum: 0,
                    DeliveryNum: +id,
                    DeliverySub: +subID,
                  }

                await Certificates.updateDeliveryByOrderOrPallet(putData)
            
            } else {

                const dataForTeuda = {
                    PalletNums: palletsArray || [],
                    OrderNum: 0,
                    CustomerID: selectedClient || 0,
                    DeliveryDate: date || '',
                    DeliveryTime: "",
                    Tranzit: tranzit && tranzit !== '0' ? +tranzit : config.create.title.tranzit[1].value as number,
                    Vehicle_Code: car,
                    SellerID: sellerID,
                    DeliveryType: teudaType
                }

                await Certificates.createCert(dataForTeuda);
            }
            setModal({
                open: true,
                value: 'תעודה נוצרה בהצלחה',
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            dispatch({ type: CLEAR_STATE_KOSHER })
            // history.goBack(LINKS.teudot.teudotMishloah.mainURL)
            history.goBack()
        } catch (err: any) {

            setModal({
                open: true,
                value: 'משהו השתבש',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    const getPalletByNum = async () => {
        dispatch({ type: LOAD })
        try {
            let res = await Pallets.getPalletWithDitailesByPalletNum(palletInput)
            setTempPalletObject({
                ...res,
                isSelected: false
            })
            setIsOpenPalletObject(true)
        } catch (err: any) {
            console.log(err.response.data.error)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }
    // console.log(tempPalletObject)
    const searchEnterPallet = () => {
        // alert(123)
        const found = palletList?.find(item => item.Pallet_Num === +palletInput)
        if (found) {

        } else {
            if (typeof +palletInput !== 'number') return
            getPalletByNum()
        }
    }

    const savePalletinTheList = async () => {
        try {
            setPalletInput('');
            if (tempPalletObject) {
                let temp: IPalletEdited = {
                    ...tempPalletObject,
                    isSelected: true,
                    CustomerID: selectedClient || 0
                }
                setPalletList(prev => {
                    return [temp, ...prev]
                })
                setIsOpenPalletObject(false)
            }
        } catch (err) { }
    }



    const isSaveAllow = useMemo(() => {
        const finder = palletList?.find(e => e.isSelected)
        return selectedClient && finder
    }, [palletList, selectedClient])

    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    useScannedDataHandler(refBody, searchEnterPallet)




    const {
        data: dataPallets
    } = useDataFetcher<IGetReuqestResponseNode>(
        Pallets.getPalletList, "data"
    )

    const {
        data: dataProductsWithVariety,
    } = useDataFetcher<IProductsWithVarietyItem[]>(
        Defaults.getProductsWithVariety, "data"
    )

    const dataProductsTypesWithID = useMemo(() => {
        if (!dataProductsWithVariety) return []
        return addIDForTheList(dataProductsWithVariety)
    }, [dataProductsWithVariety])

    const {
        data: dataGrowers
    } = useDataFetcher<IGrowerNodeGetResponse>(
        Defaults.getGrowerList, "data"
    )

    const [isOpenAddModal, setIsOpenAddModal] = useState(false)
    const [selectedPallet, setSelectedPallet] = useState<ISelectPalletProps | null>(null)
    const [sizeData, setSizeData] = useState<ISizeItem[]>([])

    const getSizes = async (productID: number) => {
        try {
            let res: ISizeItem[] = await Product.getSizesByProduct(productID);
            setSizeData(res)
        } catch (err) { }
    }

    const handleOpenAddModal = () => {
        setIsOpenAddModal(!isOpenAddModal)
    }
    const createProduct = async ({
        selectedProduct,
        GrowerID,
        PalletNum,
        Qty,
        Weight,
        PalletType,
        CustomerID,
        PackID,
        Price,
        Type_SaleCalculation,
        Remarks,
        EntryNum,
        SizeID,
        CustomerSeller,
        PalletQty
    }: IReqProduct) => {
        dispatch({ type: LOAD })
        try {
            // if (isEditMode) return // TODO AFTER CHANGE DELETE this line
            if (!selectedProduct) return
            const packType = checkPackType(packages, PackID)
            let reqData: IPostProduct = {
                QualityID: 0,
                WarehouseID: defaultArizaTranzit || 0,
                GrowerID: GrowerID,
                ProductID: selectedProduct?.ProductID,
                VarietyID: selectedProduct.VarietyID,
                PalletNum: PalletNum,
                OrderNum: 0,
                Qty: packType === '1' ? Weight : Qty,
                Weight: packType === '2' ? Qty : Weight,
                PalletType: PalletType || defaultPalletType || 0,
                OrderRowRecID: 0,
                CustomerID: CustomerID,
                ScanProblem: 0,
                ChangeReason: 0,
                PackID: PackID,
                Reseller_Code: Number(CustomerSeller.split('-')[1]),
                SalePrice: Price,
                Type_SaleCalculation: Type_SaleCalculation,
                Type_BuyCalculation: Type_SaleCalculation,
                Remarks: Remarks,
                EntryNum: EntryNum,
                SizeID: SizeID,
                PalletsQty: PalletQty
            }

            let res: {
                palletNum: number,
                message: string,
                lines18: IMarketPalletDetails[]
            } = await Market.saveProduct(reqData)

            if (!palletList) return
            const newArr = [...palletList]
            const indexPallet = newArr.findIndex(e => e.Pallet_Num === res.palletNum)

            if (indexPallet === -1) {

                const pallet = {
                    CustomerID: CustomerID,
                    Customer_Name: clients.find(e => e.CustomerID === CustomerID)?.Customer_Name || '',
                    Details: res.lines18,
                    Pallet_Date: format(new Date(), 'yyyyMMdd'),
                    Pallet_Num: res.palletNum,
                    Reseller_Code: 0,
                    Status: 0,
                }

                console.log(indexPallet)
                // append new pallet to the list
                newArr.unshift(pallet)

            } else {
                newArr[indexPallet].Details = [...res.lines18]
            }

            setPalletList(newArr)
            //   if (!newArr[custIndex].Delivery_Num) {
            //     sendMessage(JSON.stringify(newArr[custIndex]))
            //   } else {
            //     await updateTeuda(newArr[custIndex], true)
            //   }
            console.log(indexPallet)

            handleOpenAddModal()
        } catch (error: any) {
            //   errorHandling(error)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }


    const handleSelectForPallet = (pallet: number, client: number, name: string, seller: number) => {
        if(!isAllowToEdit) return
        setSelectedPallet({
            pallet,
            client,
            name,
            seller
        })
        handleOpenAddModal()
    }
    const handleNewProduct = (pallet: number) => {
        if(!isAllowToEdit) return
        handleSelectForPallet(
            pallet,
            selectedClient || 0,
            '',
            0
        )
    }
    const handleNewPallet = () => {
        if(!isAllowToEdit) return
        handleSelectForPallet(
            0,
            selectedClient || 0,
            '',
            0
        )
    }
    const handleClose = () => {
        setIsOpenSettings(false)
    }
    const addProductModalProps = {
        data: selectedPallet,
        dataGrowers: dataGrowers,
        dataPallets: dataPallets,
        packageList: packages,
        defaultPallet: defaultPalletType,
        defaultPackage: defaultPackage,
        createProduct: createProduct,
        cashProduct: () => { },
        dataProductsWithVariety: dataProductsTypesWithID,
        getSizes: getSizes,
        sizeData: sizeData,
        isWorker: isWorker,
        overweightDelta: overweightDelta
    }

    const settingsModalProps = {
        carList: carList?.cars,
        carID: car,
        setCarID: selectDriver,
        sellerList: sellerList?.sellers ,
        sellerID: sellerID,
        setSellerID: setSellerID,
        teudaTypeList: teudaTypeList ,
        teudaType: teudaType || defaultTeudaType,
        setTeudaType: setTeudaType,
        selectedClient: selectedClient,
        setSelectedClient: selectClient,
        handleClose: handleClose
    }

   
    
      const handleSelectProduct = (item: IPalletDetails) => {
        if(!isAllowToEdit) return
        setSelectedProduct(item)
        handleControlProductModal()
      }

    const handleControlProductModal = () => {
        setIsOpenProductModal(!isOpenProductModal);
    }
    
    const updateProduct = async (data: IUpdateProduct) => {
        if(!selectedProduct || !isAllowToEdit) return
        try {
        const recID = selectedProduct.RecID
        const palletNum = selectedProduct.Pallet_Num
        const res: IPalletDetails = await Market.updateProduct(recID, data)
        const updatedList = palletList.map((item) => {
            if(item.Pallet_Num === palletNum) {
            return {
                ...item,
                Details: item.Details.map((e) => 
                    e.RecID === recID ? res : e
                )
            }
            }
            return item
        })
        setPalletList(updatedList)
        handleControlProductModal()
        } catch (error) {
        console.log(error)
        } finally {

        }
    }

    const PropsForPalletItem = {
        handleSelectPallet: handleSelectPallet,
        packageHash,
        addProduct: handleNewProduct,
        handleSelectProduct: handleSelectProduct,
        deleteProduct: deleteProduct,
    }
    const selectedProductModalProps = {
        data: selectedProduct,
        updateProduct: updateProduct,
        pallets: dataPallets?.pallets || [],
        growers: dataGrowers?.growers || [],
        isWorker: false,
        packageList: packages
    }
    const selectDriverP = {
        saveFunc: selectDriver,
        list: carList,
        defaultCar: car,
        isFullObject: true
    }
    const palletToChangeProps = {
        id: +id,
        type: PalletToChangeTypes.DELIVERY,
        saveFunc: handleControlPalletToChangeModal,
        palletTypeList: dataPallets?.pallets || []
    }
    return (
        <OrderlikeLayout label={
            isEditMode ?
            t("teudot_create_page_title_ids", { id }) :
            t("teudot_create_page_title")
        } >
            {
                isOpenProductModal &&
                <ColumModal
                    title={`${selectedProduct?.ProductDesc.trim()} ${selectedProduct?.VarietyDesc.trim()} ${selectedProduct?.SizeID.trim()}`}
                    Children={ProductModal}
                    controlFunc={handleControlProductModal}
                    childrenProps={selectedProductModalProps}
                />
            }
            {
                isOpenPalletToChangeModal && isEditMode &&
                <ColumModal 
                    title={`תעודה ${id} - ${subID}`}
                    Children={PalletsToChange}
                    controlFunc={handleControlPalletToChangeModal}
                    childrenProps={palletToChangeProps}
                
                />
            }
            {
                isOpenSettings &&
                <ColumModal
                    title={t("teudot_create_modal_title_title")}
                    controlFunc={handleControlSettings}
                    Children={SettingsModsl}
                    childrenProps={settingsModalProps}
                    disableHide={!selectedClient}
                />
            }
            {
                isOpenAddModal &&
                <ColumModal
                    title={
                        selectedPallet?.pallet ?
                        t("create_product_modal_title_created", { id: selectedPallet.pallet }) :
                        t("create_product_modal_title_new")
                    }
                    Children={AddProductModal}
                    controlFunc={handleOpenAddModal}
                    childrenProps={addProductModalProps}
                />
            }
            {
                isOpenSelectDriver &&
                <ColumModal
                    title='בחר נהג'
                    controlFunc={() => { setIsOpenSelectDriver(false) }}
                    Children={SelectDriver}
                    childrenProps={selectDriverP}
                />
            }
            {
                isOpenPalletObject &&
                <ColumModal
                    title={"אישור שליפת משטח"}
                    controlFunc={() => { setIsOpenPalletObject(false) }}
                    Children={() =>
                        <TitlesList
                            list={[tempPalletObject]}
                            uniqueKey={'Pallet_Num'}
                            Element={PalletItem}
                            noPadding={false}
                            additionalData={{
                                isDisabled: true
                            }}
                        />
                    }
                    childrenProps={{}}
                    acceptFunction={savePalletinTheList}
                />
            }
            {
                isEditMode && (
                    <TitleTeuda 
                        {...titleTeuda}
                        handleControlModal={handleControlPalletToChangeModal}
                    />
                )
            }
            {
                !isEditMode &&
                <ControlSearchPannelLayout>
                    <DatePickerModule
                        multyDate={false}
                        setSelectedDateStart={setDate}
                        defEndDate={false}
                        disabled={false}
                        selected={false}
                        ignorBrod={true}
                        setSelectedDateEnd={false}
                        large={true}
                    />
                    <CircleActiveFilterBtn
                        func={handleControlSettings}
                    >
                        <SettingMiksher />
                    </CircleActiveFilterBtn>
                    
                </ControlSearchPannelLayout>
            }
            {

                (selectedClient || isEditMode) &&
                <ControlSearchPannelLayout >

                    <ScanInput
                        input={palletInput}
                        setInput={setPalletInput}
                        enterFunc={searchEnterPallet}
                        ref={refBody}
                        
                    />
                   
                    <CircleActiveFilterBtn
                        func={handleNewPallet}
                    >
                        <PlusIcon />
                    </CircleActiveFilterBtn>
                </ControlSearchPannelLayout>
            }
            {
                (selectedClient || isEditMode) &&
                <TabsBodyContent
                    activeTab={activeTab}
                    handleTabClick={handleTabClick}
                    renderPalletList={renderPalletList}
                    PropsForPalletItem={PropsForPalletItem}
                    
                />
            }
            <ControlPanelWrapper>
                <Link to={LINKS.teudot.teudotMishloah.mainURL} style={{ width: '100%' }}>
                    <ControlButton 
                        lable={t("buttons:back")} 
                    />
                </Link>
                {
                    isSaveAllow &&
                    <ControlButton 
                        lable={t("buttons:save")} 
                        handleClick={createTeuda} 
                    />
                }
            </ControlPanelWrapper>
        </OrderlikeLayout>
    );
}

export default CreateTeuda;
