import axios from 'axios'
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { LOAD, LOAD_END, LOGOUT, SELECT_TEUDA_TITLE } from '../../../../../../store/actions/actionsTypes'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import Title from '../../../../../generic/Title/Title'
import { CREATE_TEUDOT, EDIT_ORDER_ROW, GET_CAR_ARIZOT, GET_CUSTOMER_GROUP, GET_CUSTOMER_LIST, GET_DELIVERYCODES, GET_ORDER, GET_PDF_TEUDOT, GET_PRODUCT_TYPES, GET_TEUDOT, LOAD_ORDERS, WORKERS } from '../../../../../modules/API/settings'
import { BodyContainer, BodyGroupContainer, BodyGroupContentWrapper, BodyGroupTitleWrapper, IconWrapperOrdersSecond, SectionMain, SectionMainContainer } from '../likutWorkers/LikutSetWorkers'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import styled from 'styled-components'
import { ArrowIcon, CloseIcon, ErrorIcon, FilterIcon, ReloadIcon, SettingMiksher } from '../../../../../generic/icons/Icons'
import FilterModal from './FilterModal'
import { updateStatusAzmana } from '../../../../../modules/requests/updateAzmanaStatus'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import OrderGetheringItemWithDetailes from '../../../../../generic/cards/OrderGethering/OrderGetheringItemWithDetailes'
import { passOrderRowsIntoOrder, passOrderRowsIntoOrderByWeight, passTeudotIntoOrder } from '../../../../../generic/utils/passOrderRowsIntoOrder.js'
import SelectWorkerModal from './SelectWorkerModal'
import { ButtonWrapper, FilterIndicator, HeadWrapper } from '../../trucks/TrucksMain'
import { statusAzmanotList } from '../../../../../generic/artcoded/statusAzmanot'
import SelectOrderToShow from './SelectOrderToShow'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import SelectDriver from '../../trucks/SelectDriver'
import { Certificates } from '../../../../../modules/API/Certificates/certificates'
import RemarkModal from '../../arizot/View/RemarkModal'
import { Orders } from '../../../../../modules/API/Orders/orders'
import { Pallets } from '../../../../../modules/API/Pallets/pallets'
import PalletDetaileModal from './PalletDetailes/PalletDetaileModal'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import useConfigLefiMishkal from '../useConfigLefiMishkal'
import PackagePalletSummaryModal from '../Components/PackPallet/PackagePalletSummaryModal'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../Routs/config'

export const BodyContainerCustom = styled(BodyContainer)`
    border-radius: 30px;
    position: relative;
    overflow: auto;
    justify-content: flex-start;
    ::-webkit-scrollbar {
        height: 0;
    }
   
    `
export const BodyGroupContainerCustom = styled(BodyGroupContainer)`
   min-width: 380px;
    /* background: red; */
 user-select: none;
   display: ${props => props.visible ? 'flex' : 'none'};
   
`

const ControlLeft = styled.div`
    position: absolute;
    background: red;
    height: 4em;
    width: 2em; 
    z-index: 4;
    left: 0; 
    top: calc(50% - 2em);
    display: ${props => props.visible ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #297f76;
    cursor: pointer;
    border-radius: 0 25% 44% 0 / 0 35% 24% 0;
    background: rgba(255,255,255,0.9);
`
const ControlRigth = styled.div`
    position: absolute;
    /* background: red; */
    height: 4em;
    width: 2em;
    z-index: 4;
    right: 0;
    top: calc(50% - 2em);
    display: ${props => props.visible ? 'flex' : 'none'}  ;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #297f76;
    cursor: pointer;
    border-radius: 55% 0 0 44% / 35% 0 0 24%;
    background: rgba(255,255,255,0.9);
`
const ProblemIndicator = styled.div`
    position: absolute;
    top: -0.2em;
    right: -0.2em;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: tomato;
    display: ${props => props.visible ? 'flex' : 'none'};
`

const ErrorWrapper = styled.div` 
    position: absolute;
    right: -3.5em;
    top: .5em;
    background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    color: #fff;
`

export default function MonitoringMain() {
    const defaults = useSelector(e => e.defaults?.defaults)
    const defaultsTable = useSelector(e => e.defaults?.tableOfTables)
    const { transportAreas } = useSelector(e => e.defaults)
    const transportAreaDict = transportAreas?.dict
    const history = useHistory()
    const isHovaRehev = selectBrodMigdal(defaults, 340, 'Logical_Value')
    const defaultCarID = selectBrodMigdal(defaults, 427, 'Num_Value')
    const [tempTeudaData, setTempTeudaData] = useState({})
    const [isOpenSelectDriver, setIsOpenSelectDriver] = useState(false)
    const [carsInfo, setCarsInfo] = useState([])

    const patchDriver = async (id, func) => {
        let tempObj = {
            ...tempTeudaData.dataForTeuda,
            "Vehicle_Code": id
        }
        try {
            let fetchTeuda = await createTeuda(tempObj)
            setAzmanotList(tempTeudaData.curOrdersList)
            setAzmanotListDefault(tempTeudaData.defOrdersList)
            cancelFuncTeudot(true)
        } catch (err) {
            console.log(err)
            cancelFuncTeudot()
            setModal({
                open: true,
                value: err,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
    }

    const cancelFuncTeudot = (flag) => {
        setIsOpenSelectDriver(false);
        if(flag) return
        let tempArr = azmanotListDefault.map(item => {
            if(item.OrderID === tempTeudaData.dataForTeuda.OrderNum) {
                item.Status = 4
            }
            return item
        })
        listSetter(tempArr)
    }
    
    const [modal, setModal] = useContext(ContextModal);
    const scrollRef = useRef();
    const [droppbleAreas, setDroppbleAreas] = useState([])
    const [scrollFlag, setScrollFlag] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [teudot, setTeudot] = useState([]);
    const [azmanotList, setAzmanotList] = useState([]);
    const [azmanotListDefault, setAzmanotListDefault] = useState([]);
    const [detailsListDefault, setDetailsListDefault] = useState([]);

    const [siumLikutList, setSiumLikutList] = useState([]);
    const [closedList, setClosedList] = useState([]);
    const [likutList, setLikutList] = useState([]);
    const [filterFunc, setFilterFunc] = useState(null);
    const [siumInputValue, setSiumInputValue] = useState('')
    const [mishloahList, setMishloahList] = useState([]);
    const [ashlamotLikutList, setAshlamotLikutList] = useState([]);
    const ordersAll = statusAzmanotList?.filter(e => [1, 3, 4, 7, 9]?.some(item => item === e.id))
    const [visibleColumns, setVisibleColumns] = useState({
        1: true,
        3: true,
        4: true,
        7: true,
        9: true
    })

    // const [workersList, setWorkersList] = useState(null);
    const [deliveryCodes, setDeliveryCodes] = useState(null);
    const [prodTypes, setProdTypes] = useState(null);

    const [isOpenRemarkWindow, setIsOpenRemarkWindow] = useState(false);
    const [remarkWindowData, setRemarkWindowData] = useState(null);
    
    const handleRemarkModal = () => {
        setIsOpenRemarkWindow(!isOpenRemarkWindow)
    }
    const selectItemForRemark = (item) => {
        setRemarkWindowData(item)
        handleRemarkModal()
    }
    // for filter
    // const [workerGroupList, setWorkerGroupList] = useState([]);

    const workerGroupList = addIDForTheList(defaultsTable.filter(e => e.MainCode === 311 && e.SecondCode !== 0));
    const workerGroup = reduceArrayForHashTable(workerGroupList, 'SecondCode', 'Description');
    const [deliveryCodeList, setDeliveryCodeList] = useState([]);
    const [customerList, setCustomerList] = useState([])

    const [isOpenOrderFilter, setIsOpenOrderFilter] = useState(false)
    const dispatch = useDispatch();
    const getToken = useSelector(token => token.mainReducer?.token);
    const user = useSelector(token => token.mainReducer?.user);
    const defaultPackageList = useSelector(info => info.defaults.packageList);
    // WORKERS PART
    const workersArr = useSelector(list => list.defaults?.workerList);
    const workersList = workersArr.reduce((acc, cur) => {
        acc[cur.WorkerId] = cur.WorkerName
        return acc
    }, {})
    const [isOpenSelectWorker, setIsSelectWorker] = useState(false);
    const [selectedOrderForWorker, setSelectedOrderForWorker] = useState(null)
    // const isWeightCalculation = !selectBrodMigdal(defaults, 619, 'Logical_Value');
    const [isWeightCalculation, calcWeightPerUnit] = useConfigLefiMishkal()
    const [customerGroup, setCustomerGroup] = useState([]);
    const marketAreaList = defaultsTable.filter(e => e.MainCode === 300 && e.SecondCode !== 0)
    const isExistErrorRow = azmanotListDefault?.some(item => item.detailes?.some(e => e.ChangeReason === 0 && e.SupplyQty - e.OrderPacks > 0));
    const customersDict = customerList.reduce((acc, cur) => {
        acc[cur['CustomerID']] = cur
        return acc
    }, {})
    const [requestCounter, setRequestCounter] = useState(0)
    const handleModalOrders = () => {
        setIsOpenOrderFilter(!isOpenOrderFilter);
    }

    const selectIconForPalletType = (packID) => {
        if (!packID) return ''
        let filtered = defaultPackageList.filter(e => e?.PackID === packID)
        let result = filtered[0]?.Pack_Type
        return result
    }

    const listSetter = (flag) => {
        let azmanotWithDetails = flag ? flag : azmanotListDefault
        if (!flag) {
            for (let i in azmanotListDefault) {
                azmanotWithDetails[i].detailes = detailsListDefault[azmanotWithDetails[i]?.OrderID] || null
            }
        }
        let temp = reduceArrayForHashTable(azmanotWithDetails, "Status", false, 'arr')
        setSiumLikutList(temp[3] || []);
        setMishloahList(temp[4] || []);
        setAshlamotLikutList(temp[7] || []);
        setLikutList(temp[1] || [])
        setClosedList(temp[9]?.filter(e => e.teudot.length > 0) || [])
    }

    const searchSium = (input = siumInputValue, arr = (azmanotList.length > 0 ? azmanotList : azmanotListDefault)) => {
        const filtered = arr.filter(item =>
            item.OrderID.toString().toLowerCase().includes(input.toLowerCase()) ||
            item.Customer_Name.toLowerCase().includes(input.toLowerCase()) ||
            item.GroupDesc?.toLowerCase().includes(input.toLowerCase()) ||
            workersList[item.WorkerID]?.includes(input.toLowerCase()) ||
            workerGroup[item.PickupGroup]?.includes(input.toLowerCase()) ||
            deliveryCodes[item.Delivery_Code]?.includes(input.toLowerCase())
        )
        setSiumInputValue(input);
        listSetter(filtered)
    }
    const getCars = async () => {
        try {
            
            let res = await axios.get(GET_CAR_ARIZOT, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            
            setCarsInfo(res.data.cars.filter(e => e.Active))
        } catch (err) {
            console.log(err.response)
        }
    }
    const getProdTypes = async () => {
        try {
            let response = await axios.get(`${GET_PRODUCT_TYPES}`, {
                headers: {
                    Authorization: 'Bearer ' + getToken
                }
            })
            setProdTypes(response.data.productsGroups)

        } catch (err) {
            if (err.response.status !== 404) {
                setModal({
                    open: true,
                    value: err.response.data.message,
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
                if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                    dispatch({ type: LOGOUT, payload: false });
                }
            }
        }
    }

    const getClients = async () => {
        try {
            let res = await axios.get(GET_CUSTOMER_LIST, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            setCustomerList(res.data.customers)
        } catch (err) {
            console.log(err.response)
        }
    }

    const getDeliveryCodes = async () => {
        try {
            let res = await axios.get(GET_DELIVERYCODES, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            setDeliveryCodeList(res?.data?.deliveryCodes)
            let table = reduceArrayForHashTable(res?.data?.deliveryCodes, 'Delivery_Code', 'Delivery_Description');
            setDeliveryCodes(table)
        } catch (err) {
            console.log(err)
        }
    }

    const changeStatusAzmana = async (num, status) => {
        try {
            let res = await axios({
                method: 'put',
                url: GET_ORDER + num,
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: {
                    "Status": status
                }
            })
            updateStatusAzmana(num, user, status)

        } catch (err) {
            setModal({
                open: true,
                value: err.response?.data?.message,
                mood: false
            });
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'
                });
            });
        }
    }

    const putWorkersAzmanot = async (workerId, object, action = '-') => {
        try {
            let res = await axios({
                url: WORKERS,
                method: 'put',
                headers: {
                    'Authorization': 'Bearer ' + getToken
                },
                data: {
                    "Action": action,
                    'WorkerID': workerId,
                    "Orders": [object]
                }
            })
        } catch (err) {
            setModal({
                open: true,
                value: err.response.data.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
    }

    const getAzmanot = async (startDate) => {
        dispatch({ type: LOAD })
        try {
            const res = await Orders.getOrdersWithDetails(startDate, '1,3,4,7,9')
            let teudot = await getTeudat(startDate)
            const hashDetails = isWeightCalculation ?
                passOrderRowsIntoOrderByWeight(res.OrderDetails) : 
                    passOrderRowsIntoOrder(res.OrderDetails)
            const teudotHash = passTeudotIntoOrder(teudot)
            let final = res.Orders.map(item => {
                item.detailes = hashDetails[item?.OrderID] || []
                item.teudot = teudotHash[item?.OrderID] || []
                return item
            })
            setAzmanotListDefault(final)
            setDetailsListDefault(hashDetails);
            if (!filterFunc) {
                setAzmanotList(final)
                listSetter(final)
            }
            else {
                filterFunc.get(final)
            }
        } catch (err) {
            console.log(err.response)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    const getCustomerGroup = async () => {
        try {
            let res = await axios.get(GET_CUSTOMER_GROUP, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            setCustomerGroup(res.data.groups)
        } catch (err) {}
    }

    const getTeudat = async (startDate, endDate) => {
        // dispatch({ type: LOAD })
        try {
            let res = await axios.get(`${GET_TEUDOT}fromDate=${startDate}&toDate=${endDate ? endDate : startDate}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            let newArr = addIDForTheList(res.data.delivery)
            setTeudot(newArr)
            return newArr
        } catch (err) {
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        } finally {
            // dispatch({ type: LOAD_END })
        }
    }

    useEffect(() => {
        if (startDate.toString().length > 8) return
        getAzmanot(startDate);
    }, [startDate])

    useEffect(() => {
        getCustomerGroup();
        getProdTypes();
        getClients();
        getDeliveryCodes();
    }, [])

    const controlSelectWorkerBtn = (item) => {
        if (item) {
            setSelectedOrderForWorker(item)
            setIsSelectWorker(true)
        } else {
            setSelectedOrderForWorker(null)
            setIsSelectWorker(false)
        }
    }
    const selectTeudaFunc = (item) => {
       
        dispatch({type: SELECT_TEUDA_TITLE, payload: item})

      }
    const handleSaveWorker = (order, id, action = '+') => {
        putWorkersAzmanot(id, order, action).then(() => {
            const newArr = [...ashlamotLikutList]
            let found = newArr.indexOf(order)
            newArr[found] = {
                ...newArr[found],
                WorkerID: id
            }
            setAshlamotLikutList(newArr)
            controlSelectWorkerBtn()
        }).catch(err => {
            throw err
        })
    }
    const droppableAreasHelper = (sourceID) => {
        const dict = {
            'droppble5': ['droppble3'], // from teudot
            'droppble4': ['droppble3', 'droppble2', 'droppble1'], // from likut
            'droppble2': ['droppble3', 'droppble1'], // from sium likut
            'droppble3': ['droppble2', 'droppble1'], // from ashlamot
            'droppble1': ['droppble2', 'droppble3', 'droppble5'] // from mishloah
        }
        return dict[sourceID] || []
    }
 
    const onDragStart = (start, provider) => {
        const {source} = start
        setDroppbleAreas(() => droppableAreasHelper(source.droppableId))
        console.log(start)
        console.log(provider)
    }
    const onDragEnd = result => {
        const { destination, source, draggableId } = result;
        setDroppbleAreas([]);
        if (!destination) return;
        if (destination.droppableId === 'droppble4') return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        if (source.droppableId !== "droppble1" && destination.droppableId === 'droppble5') return
        // if (source.droppableId === 'droppble1' && destination.droppableId === 'droppble3')  return

        if (source.droppableId === 'droppble1') {
            console.log('drop 1')
            let selectedItem = mishloahList.splice(source.index, 1);

            if (destination.droppableId === 'droppble1') {
                //drop from mishloah => mishloah
                mishloahList.splice(destination.index, 0, ...selectedItem);
                setMishloahList(mishloahList);
            }
            else if (destination.droppableId === 'droppble5') {
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 9
                }
                
                closedList.splice(destination.index, 0, changeStatus);
                setClosedList(closedList)
                
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                // console.log(changeStatus)
                let carID = transportAreaDict[changeStatus.TransferZone]?.CarId
                if (!carID) {
                    if(isHovaRehev) {
                        if(defaultCarID !== 0) {
                            carID = defaultCarID
                        } else {
                            console.log(customersDict[changeStatus.CustomerID]?.PickupWarehouse )

                            setTempTeudaData({
                                dataForTeuda: {
                                    "PalletNums": [],
                                    "OrderNum": changeStatus.OrderID,
                                    "CustomerID": changeStatus.CustomerID,
                                    "DeliveryDate": changeStatus.Delivery_Date,
                                    "DeliveryTime": "",
                                    "Tranzit": customersDict[changeStatus?.CustomerID]?.PickupWarehouse || 0,
                                    "Vehicle_Code": carID || 0
                                },
                                curOrdersList: changeElemAzmanot,
                                defOrdersList: cangedElem
                            })
                            setModal({
                                open: true,
                                value: 'חובה לבחור רכב',
                                mood: false
                            })
                            setTimeout(() => {
                                setModal({
                                    ...modal,
                                    open: false
                                })
                            }, 3000)
                            setIsOpenSelectDriver(true)
                            return
                        }
                    }
                    
                }
                
                let dataForTeuda = {
                    "PalletNums": [],
                    "OrderNum": changeStatus.OrderID,
                    "CustomerID": changeStatus.CustomerID,
                    "DeliveryDate": changeStatus.Delivery_Date,
                    "DeliveryTime": "",
                    "Tranzit": customersDict[changeStatus?.CustomerID]?.PickupWarehouse || 0,
                    "Vehicle_Code": carID || 0
                }
                let res = createTeuda(dataForTeuda)
                
                if (res.status === 404) return
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)

            }
            else if (destination.droppableId === 'droppble2') {
                // drop from mishloah => sium
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 3
                }

                siumLikutList.splice(destination.index, 0, changeStatus)
                setSiumLikutList(siumLikutList)
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                // change status func >!<
            }
            else if (destination.droppableId === 'droppble3') {
                //drop from mishloah => ashlamot
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 7
                }
                ashlamotLikutList.splice(destination.index, 0, changeStatus);
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                setAshlamotLikutList(ashlamotLikutList)
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                // likutList.splice(source.index, 0, ...selectedItem)
                putWorkersAzmanot(changeStatus.WorkerID, changeStatus)
                // delete worker func >!<
                // change status func >!<
            }
        }

        else if (source.droppableId === 'droppble2') {
            console.log('drop 2')
            let selectedItem = siumLikutList.splice(source.index, 1);

            if (destination.droppableId === 'droppble2') {
                // drop from sium => sium
                siumLikutList.splice(destination.index, 0, ...selectedItem);
                setSiumLikutList(siumLikutList);
            }
            else if (destination.droppableId === 'droppble1') {
                //drop from sium => mishloah
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 4

                }
                mishloahList.splice(destination.index, 0, changeStatus);
                // setSiumLikutListDefault(siumLikutListDefault.filter(item => item.OrderID !== selectedItem[0].OrderID))
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                setMishloahList(mishloahList);

                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                // change status func >!<
            }
            else if (destination.droppableId === 'droppble3') {
                //drop from sium => ashlamot
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 7

                }
                ashlamotLikutList.splice(destination.index, 0, changeStatus);
                setAshlamotLikutList(ashlamotLikutList);
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                putWorkersAzmanot(changeStatus.WorkerID, changeStatus)
                // delete worker func >!<
                // change status func >!<
            }
        }
        else if (source.droppableId === 'droppble4') {
            // 'droppble4': ['droppble3', 'droppble2', 'droppble1'], // from likut
            let selectedItem = likutList.splice(source.index, 1)

            if(destination.droppableId === "droppble4") {
                likutList.splice(destination.index, 0, ...selectedItem)
                setLikutList(likutList)

            } else if (destination.droppableId === 'droppble2') {
                //drop from likut => sium
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 3

                }
                siumLikutList.splice(destination.index, 0, changeStatus);
                setSiumLikutList(siumLikutList);
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                // change status func >!<
            } else if (destination.droppableId === 'droppble1') {
                //drop from likut => mishloah
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 4

                }
                mishloahList.splice(destination.index, 0, changeStatus);
                setMishloahList(mishloahList);
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                // change status func >!<
            } else if (destination.droppableId === 'droppble3') {
                //drop from likut => ashlamot
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 7

                }
                ashlamotLikutList.splice(destination.index, 0, changeStatus);
                setAshlamotLikutList(ashlamotLikutList);
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                putWorkersAzmanot(changeStatus.WorkerID, changeStatus)
                // delete worker func >!<
                // change status func >!<
            }
 
        }
        else if (source.droppableId === 'droppble3') {
            console.log('drop 3')
            let selectedItem = ashlamotLikutList.splice(source.index, 1);

            if (destination.droppableId === 'droppble3') {
                //drop from ashlamot => ashlamot
                ashlamotLikutList.splice(destination.index, 0, ...selectedItem);
                setAshlamotLikutList(ashlamotLikutList);
            }
            else if (destination.droppableId === 'droppble2') {
                //drop from ashlamot => sium
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 3

                }
                siumLikutList.splice(destination.index, 0, changeStatus);
                setSiumLikutList(siumLikutList);
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                // change status func >!<
            }
            else if (destination.droppableId === 'droppble1') {
                //drop from ashlamot => mishloah
                let changeStatus = {
                    ...selectedItem[0],
                    Status: 4

                }
                mishloahList.splice(destination.index, 0, changeStatus);
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                setMishloahList(mishloahList);
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                // change status func >!<

            }
        } else if (source.droppableId === 'droppble5') {
            if(destination.droppableId !== "droppble3") return
            let selectedItem = closedList.splice(source.index, 1);
            if (destination.droppableId === 'droppble3') {

                let changeStatus = {
                    ...selectedItem[0],
                    Status: 7
                }
                ashlamotLikutList.splice(destination.index, 0, changeStatus);
                setAshlamotLikutList(ashlamotLikutList);
                let cangedElem = azmanotListDefault.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })

                let changeElemAzmanot = azmanotList.map(item => {
                    if (item.OrderID === selectedItem[0].OrderID) return changeStatus
                    return item
                })
                setAzmanotList(changeElemAzmanot)
                setAzmanotListDefault(cangedElem)
                changeStatusAzmana(changeStatus.OrderID, changeStatus.Status)
                // change status func >!<
            }

        }
    }

    const scroller = () => {
        if (scrollFlag) {
            setScrollFlag(false);
            scrollRef?.current?.scrollIntoView({
                behavior: 'smooth'
            })
        } else {
            setScrollFlag(true);
            scrollRef?.current?.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }

    const createTeuda = async (data) => {
        setRequestCounter(prev => prev + 1)
        try {
            let res = await Certificates.createCert(data)
            setClosedList(prev => prev.map(e => {
                if (e.OrderID === data.OrderNum) {
                    e.teudot.push({
                        ...res
                    })
                }
                return e
            }))
            changeStatusAzmana(data.OrderNum, 9)
            return true
        } catch (err) {
            console.log(err.response)
            if(err.response.status === 404) {
                changeStatusAzmana(data.OrderNum, 9)
                return
            }
            setModal({
                open: true,
                value: err.response.data.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        } finally {
            setRequestCounter(prev => prev - 1)
        }
    }
    const printPdf = async (CODE, SUBCODE) => {
        dispatch({ type: LOAD })
        try {
            // GET_PDF_TEUDOT
            let response = await fetch(`${GET_PDF_TEUDOT}deliveryNum=${CODE}&deliverySub=${SUBCODE}`, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            const data = await response.blob();
            // Receive PDF Steam as as Blob
            const file = new Blob([data], { type: 'application/pdf' });  //Create a Blob from the PDF Stream
            const fileURL = URL.createObjectURL(file);  //Build a URL from the file
            window.open(fileURL);  //Open the URL on new Window
            dispatch({ type: LOAD_END });
        } catch (err) {
            dispatch({ type: LOAD_END });
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        }
    }

    const [isOpenPalletDetailes, setIsOpenPalletDetailes] = useState(false)
    const [palletDetailesListForModal, setPalletDetailesListForModal] = useState([])
    const [selectedOrder, setSelectedOrder] = useState(null);
    const {
        data: paletTypes
      } = useDataFetcher(
        Defaults.getPalletTypes, 'data'
      )
    const getPalletDetailesByOrder = (IOrderTitleItem) => {
            setSelectedOrder(IOrderTitleItem)
            handlePalletDetailsModal()
    }
    const handlePalletDetailsModal = () => {
        setIsOpenPalletDetailes(!isOpenPalletDetailes)
    }

   
    const packagePalletProps = {
        order: selectedOrder,
    }

    useEffect(() => {
        getCars()
        scroller()
    }, [])

    const selectOrderStatusForShow = (id) => {
        setVisibleColumns({
            ...visibleColumns,
            [id]: !visibleColumns[id]
        })
    }
    const selectDriverP = {
        saveFunc: patchDriver,
        list: carsInfo
    }
    const remarkWindowProps = {
        data: remarkWindowData,
        isActive: false
      }
    
    // console.log(azmanotList)
    const handleDroppableArea = useCallback((id) => {
        return droppbleAreas.includes(id)
    }, [droppbleAreas])
    return (
        <SectionMain>
            {
                isOpenPalletDetailes &&
                <ColumModal 
                    title='סיכום משטחים ואריזות'
                    childrenProps={packagePalletProps}
                    Children={PackagePalletSummaryModal}
                    controlFunc={handlePalletDetailsModal}
                />
            }
            {isOpenRemarkWindow && (
                <ColumModal
                title={remarkWindowData?.title || ""}
                Children={RemarkModal}
                controlFunc={handleRemarkModal}
                childrenProps={remarkWindowProps}
                />
            )}
            {
                isOpenSelectDriver &&
                    <ColumModal 
                        size='md'
                        title='בחר נהג'
                        controlFunc={cancelFuncTeudot}
                        Children={SelectDriver}
                        childrenProps={selectDriverP}
                    />
            }
            {
                isOpenSelectWorker &&
                <SelectWorkerModal
                    controlFunc={controlSelectWorkerBtn}
                    title={selectedOrderForWorker.OrderID}
                    data={workersArr}
                    putWorkersAzmanot={putWorkersAzmanot}
                    order={selectedOrderForWorker}
                    saveFunc={handleSaveWorker}
                />
            }
            <SectionMainContainer >
                <Title label={requestCounter > 0 ? `בתהליך ${requestCounter}`: 'בקרה'} color={'#297f76'} />
                <HeadWrapper style={{ width: '100%' }}>
                    <DatePickerModule
                        setSelectedDateStart={setStartDate}
                    />
                    {
                        isExistErrorRow ?
                            <ErrorWrapper>
                                <ErrorIcon />
                            </ErrorWrapper>
                            : ''
                    }
                    <FilterIndicator
                        onClick={handleModalOrders}
                    >
                        <ButtonWrapper >
                            <ProblemIndicator visible={isExistErrorRow} />
                            {!isOpenOrderFilter ? <FilterIcon /> : <CloseIcon />}
                        </ButtonWrapper>
                    </FilterIndicator>
                    <FilterModal
                        isOpenOrderFilter={isOpenOrderFilter}
                        workerGroupList={workerGroupList}
                        deliveryCodeList={deliveryCodeList}
                        setIsOpenOrderFilter={setIsOpenOrderFilter}
                        azmanotListDefault={azmanotListDefault}
                        listSetter={listSetter}
                        setAzmanotList={setAzmanotList}
                        searchSium={searchSium}
                        siumInputValue={siumInputValue}
                        prodTypes={prodTypes}
                        customerList={customerList}
                        marketAreaList={marketAreaList}
                        isExistErrorRow={isExistErrorRow}
                        setFilterFunc={setFilterFunc}
                        customersDict={customersDict}
                        customerGroup={customerGroup}
                    />
                    <SelectOrderToShow
                        controlFunc={selectOrderStatusForShow}
                        data={ordersAll}
                        state={visibleColumns}

                    />
                    <FilterIndicator
                        onClick={() => {
                            getAzmanot(startDate)
                        }}
                    >
                        <ButtonWrapper>
                            <ReloadIcon />
                        </ButtonWrapper>
                    </FilterIndicator>
                    <SearchInput setInput={searchSium} input={siumInputValue} value={"חיפוש"} />

                </HeadWrapper>
                <ControlLeft visible={scrollFlag} onClick={scroller}><ArrowIcon position={'left'} /></ControlLeft>
                <ControlRigth visible={scrollFlag} onClick={scroller}><ArrowIcon /></ControlRigth>
                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    <BodyContainerCustom >
                        <BodyGroupContainerCustom visible={visibleColumns['1']} ref={scrollFlag ? scrollRef : null}>
                            <BodyGroupTitleWrapper>
                                ליקוט
                                <IconWrapperOrdersSecond >
                                    {likutList?.length}
                                </IconWrapperOrdersSecond>
                            </BodyGroupTitleWrapper>
                            <Droppable droppableId={'droppble4'}>
                                {provided =>
                                    <BodyGroupContentWrapper
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        isAllowDrop={handleDroppableArea(provided.droppableProps["data-rbd-droppable-id"])}

                                    >
                                        {likutList?.map((item, index) =>
                                            <OrderGetheringItemWithDetailes
                                                {...item}
                                                Color={customersDict[item.CustomerID].Color}
                                                index={index}
                                                isWeightCalculation={isWeightCalculation}
                                                key={item.OrderID}
                                                workersList={workersList}
                                                selectIconForPalletType={selectIconForPalletType}
                                                deliveryCodes={deliveryCodes}
                                                selectProductForRemark={selectItemForRemark}
                                                item={item}
                                            />
                                        )}
                                        {provided.placeholder}
                                    </BodyGroupContentWrapper>
                                }
                            </Droppable>
                        </BodyGroupContainerCustom>
                        <BodyGroupContainerCustom visible={visibleColumns['3']}>
                            <BodyGroupTitleWrapper>
                                סיום ליקוט
                                <IconWrapperOrdersSecond >
                                    {siumLikutList?.length}
                                </IconWrapperOrdersSecond>
                            </BodyGroupTitleWrapper>
                            <Droppable droppableId={'droppble2'}>
                                {provided =>
                                    <BodyGroupContentWrapper
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        isAllowDrop={handleDroppableArea(provided.droppableProps["data-rbd-droppable-id"])}


                                    >
                                        {siumLikutList?.map((item, index) =>
                                            <OrderGetheringItemWithDetailes
                                                {...item}
                                                Color={customersDict[item.CustomerID].Color}
                                                index={index}
                                                isWeightCalculation={isWeightCalculation}
                                                key={item.OrderID}
                                                workersList={workersList}
                                                selectIconForPalletType={selectIconForPalletType}
                                                deliveryCodes={deliveryCodes}
                                                selectProductForRemark={selectItemForRemark}
                                                item={item}
                                            />
                                        )}
                                        {provided.placeholder}
                                    </BodyGroupContentWrapper>
                                }
                            </Droppable>
                        </BodyGroupContainerCustom>
                        <BodyGroupContainerCustom visible={visibleColumns['7']}>
                            <BodyGroupTitleWrapper>
                                השלמות
                                <IconWrapperOrdersSecond>
                                    {ashlamotLikutList?.length}
                                </IconWrapperOrdersSecond>


                            </BodyGroupTitleWrapper>
                            <Droppable droppableId={'droppble3'}>
                                {provided =>
                                    <BodyGroupContentWrapper
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        isAllowDrop={handleDroppableArea(provided.droppableProps["data-rbd-droppable-id"])}
                                        // style={handleDroppableArea(provided.droppableProps["data-rbd-droppable-id"]) ? {background: 'red', transition: '0.5s'} : {background: 'none', transition: '0.5s'}}
                                    >
                                        {ashlamotLikutList?.map((item, index) =>
                                            <OrderGetheringItemWithDetailes
                                                {...item}
                                                Color={customersDict[item.CustomerID].Color}
                                                index={index}
                                                isWeightCalculation={isWeightCalculation}
                                                key={item.OrderID}
                                                workersList={workersList}
                                                selectIconForPalletType={selectIconForPalletType}
                                                deliveryCodes={deliveryCodes}
                                                actionFunc={controlSelectWorkerBtn}
                                                item={item}
                                                selectProductForRemark={selectItemForRemark}

                                            />)}
                                        {provided.placeholder}
                                    </BodyGroupContentWrapper>
                                }
                            </Droppable>
                        </BodyGroupContainerCustom>

                        <BodyGroupContainerCustom visible={visibleColumns[4]} >
                            <BodyGroupTitleWrapper>
                                מוכנה למשלוח
                                <IconWrapperOrdersSecond >
                                    {mishloahList?.length}
                                </IconWrapperOrdersSecond>
                            </BodyGroupTitleWrapper>
                            <Droppable droppableId={'droppble1'}>
                                {provided =>
                                    <BodyGroupContentWrapper
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        isAllowDrop={handleDroppableArea(provided.droppableProps["data-rbd-droppable-id"])}

                                    >
                                        {mishloahList?.map((item, index) =>
                                            <OrderGetheringItemWithDetailes
                                                index={index}
                                                item={item}
                                                isWeightCalculation={isWeightCalculation}
                                                Color={customersDict[item.CustomerID].Color}
                                                key={item.OrderID}
                                                {...item}
                                                workersList={workersList}
                                                selectIconForPalletType={selectIconForPalletType}
                                                deliveryCodes={deliveryCodes}
                                                selectProductForRemark={selectItemForRemark}
                                                handlePalletDetailsModal={getPalletDetailesByOrder}
                                            />)}
                                        {provided.placeholder}
                                    </BodyGroupContentWrapper>
                                }
                            </Droppable>
                        </BodyGroupContainerCustom>
                        <BodyGroupContainerCustom visible={visibleColumns[9]} ref={!scrollFlag ? scrollRef : null}>
                            <BodyGroupTitleWrapper>
                                תעודות
                                <IconWrapperOrdersSecond >
                                    {closedList?.length}
                                </IconWrapperOrdersSecond>
                            </BodyGroupTitleWrapper>
                            <Droppable droppableId={'droppble5'}>
                                {provided =>
                                    <BodyGroupContentWrapper
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        isAllowDrop={handleDroppableArea(provided.droppableProps["data-rbd-droppable-id"])}

                                    >
                                        {closedList?.map((item, index) =>
                                            <OrderGetheringItemWithDetailes
                                                index={index}
                                                isWeightCalculation={isWeightCalculation}
                                                Color={customersDict[item.CustomerID].Color}
                                                key={item.OrderID}
                                                {...item}
                                                workersList={workersList}
                                                selectIconForPalletType={selectIconForPalletType}
                                                deliveryCodes={deliveryCodes}
                                                actionFunc={printPdf}
                                                selectProductForRemark={selectItemForRemark}
                                                item={item}
                                                selectTeudaFunc={selectTeudaFunc}
                                                handlePalletDetailsModal={getPalletDetailesByOrder}

                                            />)}
                                        {provided.placeholder}
                                    </BodyGroupContentWrapper>
                                }
                            </Droppable>
                        </BodyGroupContainerCustom>
                    </BodyContainerCustom>
                </DragDropContext>
            </SectionMainContainer>
        </SectionMain>
    )
}


const reduceArrayForHashTable = (arr, key, value, valueType = 'string') => {
    let reducedArr = arr.reduce((acc, cur) => {
        if (valueType === 'arr') {
            // console.log(cur[key])
            acc[cur[key]] = acc[cur[key]]?.concat(cur) || [].concat(cur)
        }
        else {
            acc[cur[key]] = cur[value]?.trim()
        }
        return acc

    }, {})
    return reducedArr
}