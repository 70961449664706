import React, { useCallback, useMemo, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import PropTypes, { func } from 'prop-types'
import { CalendatDay, EditIcon, InvoiceIcon, MoonIcon, PackageIcon, PrintIcon, SpeedIcon, SunIcon, UserIcon, VerticalDots } from '../../icons/Icons'
import CirclePercentElement from '../../particles/CirclePercentElement'
import { convertToDate } from '../../../modules/convertDateString'
import DetailesItem from '../../../main/second/mainbar/likutExpress/monitoring/DetailesItem'
import TitlesList from '../../titlesRowItem/TitlesList'
import { statusConverter } from '../../artcoded/statusAzmanot'
import DropdownByIcon from '../../../main/second/mainbar/azmanotRehesh/Layouts/DropdownByIcon'
import { Link } from 'react-router-dom'
import { LINKS } from '../../../Routs/config'

export const MainItemWrapper = styled.div`
    background: rgba(41,127,118,0.31);
    width: 100%;
    /* min-height: 5em; */
    border-radius: 30px;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    
`

export const HeadContentWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 5em;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    justify-content: flex-start;
    /* background: #fff; */
    border-radius: 30px;
    background: ${props => props.color ? `#${props.color}` : '#fff'};
    color: #75759e;
    padding: 0.5em 2.5em;
    gap: 0.2em;
`
const HeadContentElement = styled.div`
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
    font-size: ${props => props.primal ? '1.1em' : props.date ? '0.9em' : '1em'};
    /* color: #75759e; */
`
export const DetailesBody = styled.div`
    padding: 0.5em;
    display: ${props => props.isActive ? 'flex' : 'none'};
`

const IconWrapper = styled.div`
    color: ${props => props.primal ? 'tomato' : ''};

`
export const QtyBoxWrapper = styled.div`
    position: absolute;
    left: 1em;
    top: .75em;
    display: flex;
    justify-content: center;
    align-items: center;

    `
    const OverlayButton = styled.div`
        position: absolute;
        display: none;
        ${QtyBoxWrapper}:hover & {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            height: 80%;
            background: #fff;
            border-radius: 30px;
            color: #297F76;
            font-size: 1.2em;
            cursor: pointer;
        }
    `
export const SideBlock = styled.div`
    position: absolute;
    height: 2em;
    width: 4em;
    background: ${props => props.primal ? props.primal : '#d3ffce'};
    transform: rotate(-90deg);
    top: 1.35em;
    right: -1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 1em;
    font-weight: 600;
    color: #75759e;
    box-shadow: -3px -3px 5px #cecece;
`
const KwucaBlock = styled.div`
    padding: 0.2em 0.4em;
    background: ${props => props.primal ? '#faceff' : '#d3ffce'};
    border-radius: 10px;
    transform: translateY(-0.2em);
`
export const SelectWorkerButton = styled.div`
    border-radius: 5px;
    color: #ffff;
    padding: 0.1em 0.5em;
    display: flex;
    align-items: center;
    background: #297f76;
    transform: translateY(-2px);
    font-size: 0.7em;
    cursor: pointer;
`
 const OrderGetheringItemWithDetailes = ({
    Customer_Name, OrderID, index, Delivery_Date, detailes, Type, Products, 
    actionFunc, item, teudot, selectProductForRemark, selectTeudaFunc=undefined,
    workersList, WorkerID, GroupDesc, ProductsWithSupplyPacks,
    selectIconForPalletType, Status, GroupID, Delivery_Code,  CustomerID,
    Packs, openEditRemarkLikut, Color, handlePalletDetailsModal, isWeightCalculation
}) => {
    const [showDetailes, setShowDetailes] = useState(false)
    const checkForResons = useMemo(() => detailes?.some(e => e.ChangeReason !== 0) ,[detailes]) 
    const {status, color} = useMemo(() => {return statusConverter(Status)}, [Status]) 
    const currentDate = useMemo(() => convertToDate(Delivery_Date), [Delivery_Date])
    console.log(item)
    const calcWeightPerUnit = (item) => {
        return item.SupplyWeight / item.SupplyQty
    }
    const detailesRenderQuery = useCallback((item) => {
        if(isWeightCalculation) {
            return (item.ChangeReason !== 0 || (item.ChangeReason === 0 && (Math.abs(item.SupplyWeight - item.OrderWeight) > calcWeightPerUnit(item))))
        } else {
            return (item.ChangeReason !== 0 || (item.ChangeReason === 0 && item.SupplyQty - item.OrderPacks > 0))
        }
    }
    , []) 
    let showAcpaca = Type === 1 ? <InvoiceIcon /> : <SpeedIcon color={'tomato'} />

    const handleClick = (e) => {
        e.stopPropagation()
        if(Status !== 9) return
        const obj = teudot.at(-1)
        // console.log(obj)
        actionFunc(obj.Delivery_Num | obj.deliveryNum, obj.Delivery_Sub | obj.deliverySub)
    }
    const handleDropDown = (e) => {
        // console.log(`#${Color}`)

        setShowDetailes(!showDetailes);
    }
    const handleOpenRemark = (e) => {
        e.stopPropagation()
        openEditRemarkLikut(item) 
    }
    const handleOpenSelectWorker = (e) => {
        e.stopPropagation()
        actionFunc(item) 
    }
    const hadlePalletDetailsByOrder = (e) => {
        if(!handlePalletDetailsModal) return
        e.stopPropagation()
        handlePalletDetailsModal(item)
    }

    const handleOpenMenu = e => {
        e.stopPropagation()
    }
    const linkToTeuda = (e) => {
        e.stopPropagation()
        console.log('123')
        if(!teudot[0]) return
        selectTeudaFunc(teudot[0])
    }
    return (
        <Draggable 
            draggableId={OrderID?.toString()} 
            index={index} 
            key={OrderID} 
            
        >
            {
                provided =>
                    <MainItemWrapper
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        onClick={handleDropDown}
                    >
                        <HeadContentWrapper
                            color={Color?.trim()}
                        >
                            <SideBlock primal={color}>{status}</SideBlock>
                            <QtyBoxWrapper 
                                onClick={handleOpenMenu}
                            >   
                                {
                                    handlePalletDetailsModal &&
                                    <OverlayButton 
                                        onClick={hadlePalletDetailsByOrder}
                                    >
                                        <PackageIcon />
                                    </OverlayButton>
                                }
                                <CirclePercentElement
                                    content={
                                        Status === 9 ? 
                                        <DropdownByIcon
                                            icon={<VerticalDots />}
                                            
                                        >
                                        <WrapperMenu>
                                            <Link
                                                onClick={linkToTeuda}
                                                to={`${LINKS.teudot.teudotMishloah.createByID}${teudot[0]?.Delivery_Num}/${teudot[0]?.Delivery_Sub}`}
                                                target='_blank'
                                            >

                                                <IconWrapperMenu
                                                    onClick={linkToTeuda}
                                                >
                                                    <EditIcon />
                                                </IconWrapperMenu>
                                            </Link>
                                            <IconWrapperMenu
                                                onClick={handleClick}
                                            >
                                                <PrintIcon />
                                            </IconWrapperMenu>
                                            {
                                                handlePalletDetailsModal &&
                                                <IconWrapperMenu 
                                                    onClick={hadlePalletDetailsByOrder}
                                                >
                                                    <PackageIcon />
                                                </IconWrapperMenu>
                                            }
                                            {/* <IconWrapperMenu
                                                onClick={handleClick}
                                            >
                                                <PrintIcon />
                                            </IconWrapperMenu> */}

                                        </WrapperMenu>
                                        </DropdownByIcon> 
                                        : Math.round(ProductsWithSupplyPacks / Products * 100) + "%"
                                    }
                                    problem={checkForResons}
                                />
                               
                            </QtyBoxWrapper>
                            <HeadContentElement>
                                
                                <HeadContentElement date>
                                    {Packs}
                                    <IconWrapper>
                                        <PackageIcon />
                                    </IconWrapper>
                                    {currentDate}
                                    <CalendatDay />
                                </HeadContentElement>
                                {OrderID}
                                <IconWrapper>
                                    {showAcpaca}
                                </IconWrapper>
                                {/* <UserIcon /> */}
                            </HeadContentElement>
                            <HeadContentElement primal >
                                {Customer_Name?.trim()}
                                <UserIcon />
                            </HeadContentElement>
                            <HeadContentElement  >
                                {
                                    workersList && workersList[WorkerID] ?
                                        [
                                            <p style={{ color: 'tomato' }}>{workersList[WorkerID]}</p>,
                                            <p style={{ color: 'tomato' }}><UserIcon /></p>
                                        ]
                                        : ''
                                }
                                {
                                    Status === 7 && item && !openEditRemarkLikut &&
                                    <SelectWorkerButton onClick={handleOpenSelectWorker}>
                                        <UserIcon />

                                    </SelectWorkerButton>
                                }
                                {
                                     item && openEditRemarkLikut &&
                                    <SelectWorkerButton onClick={handleOpenRemark}>
                                        <EditIcon />

                                    </SelectWorkerButton>
                                }
                                
                                <KwucaBlock primal={GroupID !== 6}>
                                    {GroupDesc?.trim()}
                                </KwucaBlock>
                                {
                                    Delivery_Code === 1 ?
                                        <SunIcon /> :
                                        <MoonIcon />
                                }
                            </HeadContentElement>
                        </HeadContentWrapper>
                        <DetailesBody isActive={showDetailes && checkForResons}>
                            <TitlesList 
                                list={detailes}
                                uniqueKey={'RecID'}
                                queryRender={detailesRenderQuery}
                                Element={DetailesItem}
                                additionalData={{
                                    isWeightCalculation: isWeightCalculation,
                                    selectIconForPalletType: selectIconForPalletType,
                                    selectProductForRemark: selectProductForRemark
                                }}
                            />
                        </DetailesBody>
                    </MainItemWrapper>
            }
        </Draggable>
    )
}

export default React.memo(OrderGetheringItemWithDetailes)

OrderGetheringItemWithDetailes.propTypes = {
    Customer_Name: PropTypes.string,
    OrderID: PropTypes.number,
    Delivery_Date: PropTypes.string,
    detailes: PropTypes.array,
    WorkerID: PropTypes.number,
    GroupDesc: PropTypes.string,
    GroupID: PropTypes.number,
    selectIconForPalletType: PropTypes.func,
    Delivery_Code: PropTypes.number,
    index: PropTypes.number,
    Type: PropTypes.number,
    Products: PropTypes.number,
    workersList: PropTypes.object,
    Status: PropTypes.number,
    Packs: PropTypes.number,
    actionFunc: PropTypes.func,
    item: PropTypes.object,
    openEditRemarkLikut: PropTypes.func || undefined,
    handlePalletDetailsModal: PropTypes.func,
}


const WrapperMenu = styled.div`
    padding: 0.1em;
    display: flex;
    gap: 0.5em;
`
const IconWrapperMenu = styled.div`
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border-radius: 50%;
    background-color: #fff;
    color: ${props => props.isPrimal ? 'tomato' : '#297F76'} ;
    
    cursor: pointer;
    
`