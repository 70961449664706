import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { PhoneIcon, UserIcon } from '../generic/icons/Icons'
import FloatingLabelInput from './InputField'
import useDebounce from '../main/second/mainbar/seller/Helpers/useDebounce'
import TwoFactorForm from './TwoFactorForm'

type Props = {}

const RegistrationPage = (props: Props) => {
  // get params from url
  const [tel, setTel] = useState<string>('')
  const [isMatchCode, setIsMatchCode] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')

  // use for check if user name is available -->
  const [debouncedUserName, setDebouncedUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [codeSMS, setCodeSMS] = useState<string>('')
  const [isAwaitingCodeVerifying, setIsAwaitingCodeVerifying] = useState<boolean>(false)
  const location = useLocation()
  const token = location.search.split('=')[1]

  const handleTelephone = (e: React.ChangeEvent<HTMLInputElement>) => {
  
    if(!e.target.value[0] ) {
      setTel('')
      return
    }
    if(e.target.value[0] !== '0') {
      return
    }
    if(e.target.value.length > 10) return
    setTel(e.target.value)
  }

  const handleModal = () => {
    setIsShowModal(!isShowModal)
  }

  const verifySMS = async () => {
   
    if(codeSMS.split('').length !== 4) return
    setIsAwaitingCodeVerifying(true)
    try {
      const res = true
      setIsMatchCode(res)
      if(res) {
        setIsShowModal(false)
      }

    } catch (error) {
      console.log(error)
    } finally {
      setIsAwaitingCodeVerifying(false)
    }
  };

  const handleCodeSms = (code: string) => {
    setCodeSMS(code)
  }

  const debounced = useDebounce(userName, 2000)

  const handleUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value)
  }

  useEffect(() => {
    if(debounced.length < 3) return
    console.log('debounced', debounced)
    setDebouncedUserName(debounced)
  }, [debounced])
  
  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }
  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }
  const restDidgitsTel = 10 - tel.length

  const isAlertPassword = !!password && !!confirmPassword && password !== confirmPassword
  
  return (
    <Section>
      {
        isShowModal ?
        <FormWrapper>
          <TwoFactorForm 
            setCodeSMS={handleCodeSms}
            codeSMS={codeSMS}
            isLoading={isAwaitingCodeVerifying}
            verify={verifySMS}
          />

        </FormWrapper> : ""

      }
      <Container>
        <Wrapper>
          <MainBlock>
          <ContentWrapper logo>
              <Tag htmlFor="login" logo>
                  הרשמה   
                  
                  <BackBox />
              </Tag>
          </ContentWrapper>
          {/* <h1>Registration Page</h1> */}
          <ContentWrapper>
            <FloatingLabelInput
              tag='שם משתמש'
              icon={<UserIcon />}
              autoComplete='off'
              onChange={handleUserName}
            />
          </ContentWrapper>
          <ContentWrapper>
                            
              <FloatingLabelInput 
                  tag='סיסמה'
                  icon={<i className="fas fa-lock"></i>}
                  onChange={handlePassword} 
                  type={'password'}
                  autoComplete='new-password'
                  alert={isAlertPassword}
              />
          </ContentWrapper>
          <ContentWrapper>
                            
              <FloatingLabelInput 
                  tag='לאשר את הסיסמה'
                  icon={<i className="fas fa-lock"></i>}
                  onChange={handleConfirmPassword} 
                  type={'password'}
                  autoComplete='new-password'
                  alert={isAlertPassword}
              />
          </ContentWrapper>
          <ContentWrapper>  
              <FloatingLabelInput 
                  tag='טלפון נייד'
                  icon={<PhoneIcon />}
                  value={tel}
                  onChange={handleTelephone} 
                  type={'tel'}
                  helperText={`נשארו ${restDidgitsTel} ספרות`}
              />
          </ContentWrapper>
         
         
          {
            !isMatchCode ?
            <ContentWrapper button>
              <Button
              type='button'
                onClick={handleModal}
                // disabled={tel.length !== 10 || !userName || !password || !confirmPassword}
              >
                <p>send sms</p>
                <i className="fas fa-spinner"></i>
              </Button>
            </ContentWrapper>
            : ""
          }
          <Link to={`/login?token=${token}`}>כניסה</Link>
          
          </MainBlock>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default RegistrationPage

const Section = styled.section`
    width: 100%;
    height: 100vh;
    background: #e9f0f0;
    position: fixed;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`
const FormWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: rgba(0,0,0,0.5);
    padding: 0 0.5em;
`
const Container = styled.div`
    width: 100%;
    height: 100%;
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
    
    
`
const Wrapper = styled.form`
    color: #1F1F1F;
    width: 100%;
    height: 550px;
    background: #fdfdfd;
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41));
    z-index: 2;
    @media screen and (min-width: 400px) {
        width: 400px;
    }
    /* ::before {
        content: "";
        position: absolute;
        width: 5%;
        height: 85%;
        background: #e9f3f2;
        left: -5%;
        top: 2.2em;
        border-radius: 30px 0 0 30px;
        z-index: -1;


    } */
`
const MainBlock = styled.div`
    width: 100%;
    height: 80%;
    /* background: blue; */
    display: flex;
    flex-flow: column;
    gap: 1em;
    
    align-items: center;
    justify-content: space-between;
    
`
export const ContentWrapper = styled.div<{row?: boolean, logo?: boolean, button?: boolean}>`
    width: 80%;
    height: ${props => props.logo ? '15%' : props.button ? '12%' : '20%'};
    display:flex;
    flex-flow: ${props => props.row ? 'row' : 'column'};
    align-items: ${props => props.row ? 'center' : 'flex-end'} ;
    justify-content: ${props => props.row ? 'flex-end' : 'center'};
    
`

export const InputContainer = styled.div`
    position: relative;
    width: 100%;
    height: 60%;
    /* background: blueviolet; */
    margin-top: 0.3em;
`
export const Input = styled.input`
    height: 100%;
    width: 100%;
    border-radius: 30px;
    padding: 0.2em 0.5em;
    outline: none;
    text-align: right;
    border: 2px solid #7ab0ab;
    font-size: 1.2em;
    -webkit-appearance: none;
    background: #f2f2f2;
    @keyframes border-pulsate {
        
        50%  { border-color: rgba(128,224,129,1); }
        
    }
    &:focus{
        animation: border-pulsate 2s infinite;
    } 
`
export const IconInput = styled.div`
    position: absolute;
    height: 100%;
    width: 2.5em;
    /* background: red; */
    i {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.5s ease;
        color: #1F1F1F;
    }
`
const Button = styled.button`
    width: 100%;
    height: 100%;
    background: #7ab0ab;
    border: none;
    border-radius: 30px;
    font-size: 1.2em;
    color: #fff;
    cursor: pointer;
    transition: 0.2s ease;

    :active{
        background: #297f76;
    }

    :disabled{
        p {
            display: none;
        }
        i {
            display: block;
            // padding: 0.2em 1em;
        }
    }
    i {
        display: none;
        animation: spin 1s infinite ;
        @keyframes spin {
            0% {transform: rotate(0);}
            100% {transform: rotate(360deg);}
        }
    }
`
export const Tag = styled.label<{logo?: boolean}>`
    font-size: ${props => props.logo ? '2em' : '1em'};
    font-family: sans-serif;
    position: relative;
    z-index: 2;
`
export const BackBox = styled.div`
    position: absolute;
    width: 2.5em;
    left: -0.4em;
    top: 0.5em;
    height: 50%;
    background: rgba(122,176,171, 0.7);
    z-index: -1;
`