import React, { useEffect, useRef } from "react";
import styled from "styled-components";



// Container для самой формы
const FormContainer = styled.form`
  /* CSS-переменные из вашего CSS */
  --black: #000000;
  --ch-black: #145a32;       /* Тёмно-зелёный, аналог чёрного */
  --night-rider: #1e8449;    /* Основной зелёный (более светлый) */
  --ch-white: #82e0aa;       /* Светло-зелёный для бекграунда инпутов */
  --af-white: #d1f2eb;       /* Ещё более светлый зелёный */
  --white: #ffffff;          /* Чисто белый */
  --tomato: #0b3d02;         /* Самый тёмный зелёный (вместо красного) */
  --glow-color: #82e0aa;     /* Обводка формы (зелёная подсветка) */
  /* Дополнительно можно определить переменную --glow-color, 
     если она использовалась для border (неявно в исходном CSS) */
  --glow-color: #eee;

  border: 2px solid var(--glow-color);
  padding: 25px;
  display: flex;
  max-width: 420px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: var(--night-rider);
  background-color: var(--white);
  border-radius: 15px;
  position: relative;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
`;

// Кнопка закрытия (X)
const CloseButton = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: var(--ch-white);
  color: var(--af-white);
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  transition: 0.5s ease;

  &:hover {
    background-color: var(--tomato);
    color: var(--white);
  }
`;

// Обертка для заголовка/описания
const InfoContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

// Заголовок
const Title = styled.span`
  font-size: 1.5rem;
  font-weight: 900;

`;

// Описание
const Description = styled.p`
  margin-top: 10px;
  font-size: 1rem;
`;

// Обертка для полей ввода
const InputFields = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

// Само поле ввода (4 штуки)
const CodeInput = styled.input`
  height: 2.5em;
  width: 2.5em;
  outline: none;
  text-align: center;
  font-size: 1.5rem;
  color: var(--ch-black);
  border-radius: 10px;
  border: 2.5px solid var(--ch-white);
  background-color: var(--ch-white);
  transition: 0.5s;

  &:focus {
    border: 1px solid var(--night-rider);
    /* box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.15); */
    transform: scale(1.05);
  }
`;

// Контейнер для кнопок
const ActionButtons = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 0.5rem;
`;

// Кнопка "Verify"
const VerifyButton = styled.a`
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  background: var(--ch-black);
  border: 1px solid var(--night-rider);
  transition: 0.5s ease;
  user-select: none;
  text-shadow: none;

  &:hover,
  &:focus {
    color: var(--white);
    background: var(--night-rider);
  }
`;

// Кнопка "Clear"
const ClearButton = styled.a`
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--night-rider);
  background: transparent;
  border: 1px solid var(--night-rider);
  transition: 0.5s ease;
  user-select: none;
  text-shadow: none;

  &:hover,
  &:focus {
    color: var(--tomato);
    background-color: var(--white);
    border: 1px solid var(--tomato);
  }
`;

// Интерфейс пропсов, если нужно что-то передавать.
// Пока оставим пустым, поскольку форма статична.
interface TwoFactorFormProps {
  codeSMS: string;
  verify: () => void;
  isLoading: boolean;
  setCodeSMS: (code: string) => void;
}


// Основной компонент
const TwoFactorForm: React.FC<TwoFactorFormProps> = ({
  codeSMS,
  verify,
  isLoading,
  setCodeSMS,
}) => {

  const inputRefs = useRef<HTMLInputElement[]>([]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const value = e.target.value;
    // Если ввели символ и он не пустой, переходим к следующему
    if (value && value.length === 1 && idx < inputRefs.current.length - 1) {
      inputRefs.current[idx + 1].focus();
    }
   
      const newCode = [...codeSMS.split("")];
      newCode[idx] = value;
      setCodeSMS(newCode.join(""));
  };
  useEffect(() => {
    // 1. Проверяем, поддерживается ли Web OTP (navigator.credentials)
    //    и есть ли тип "OTPCredential".
    //    Тип "OTPCredential" может не определяться в TS, так что используем приведение типов.
    if (!("OTPCredential" in window)) {
      console.log("Web OTP API не поддерживается в этом браузере.");
      return;
    }

    // 2. Создаём AbortController на случай, если захотим отменить запрос
    const ac = new AbortController();

    // 3. Запрашиваем код
    //    transport: ["sms"] — говорит, что хотим получить одноразовый код из SMS
    //    signal: ac.signal — для отмены
    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.signal,
      } as any) // "as any" из-за неполных типов в TS
      .then((otp: any) => {
        if (!otp) return;
        // otp.code — строка с кодом, который браузер извлёк из SMS
        const codeStr: string = otp.code; // Например, "1234"

        // 4. Раскладываем код по инпутам
        //    Если код, например, из 4 символов
        codeStr.split("").forEach((char, i) => {
          if (inputRefs.current[i]) {
            inputRefs.current[i].value = char;
          }
        });
      })
      .catch((err: any) => {
        console.log("Ошибка при получении OTP:", err);
      });

    // 5. Очистка — при размонтировании отменяем запрос
    return () => {
      ac.abort();
    };
  }, []);
  
  const clearForm = () => {
    inputRefs.current.forEach((el) => (el.value = ""));
    inputRefs.current[0].focus();
    setCodeSMS("");
  }
  return (
    <FormContainer>
      <CloseButton>X</CloseButton>

      <InfoContainer>
        <Title>בדיקת תקפות הטלפון</Title>
        <Description>
        הזן את הקוד מה-SMS ואשר
        </Description>
      </InfoContainer>

      <InputFields>
        {[0, 1, 2, 3].map((i) => (
          <CodeInput
            key={i}
            type="tel"
            maxLength={1}
            ref={(el) => {
              if (el) inputRefs.current[i] = el;
            }}
            onChange={(e) => handleChange(e, i)}
          />
        ))}
      </InputFields>

      <ActionButtons>
        <VerifyButton
          onClick={verify}
        >Verify</VerifyButton>
        <ClearButton 
          onClick={clearForm}
        >
          Clear
        </ClearButton>
      </ActionButtons>
    </FormContainer>
  );
};

export default TwoFactorForm;