import React from 'react'
import styled from 'styled-components'
import { AppleIcon, BasketIcon, DeleteIcon, EditIcon, ScalesIcon } from '../../../../../generic/icons/Icons'
import { stylePackByID } from '../../../../../generic/utils/stylePackByPackType'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import { IPalletDetailes } from '../../../../../modules/API/Pallets/models/palletList'
import ProductImage from '../../queries/TeudotDetails/Components/ProductImage'
import DropdownByIcon from '../../azmanotRehesh/Layouts/DropdownByIcon'

interface IPalletDetailesItem extends IPalletDetailes {
    packageHash?: Record<number, IPackagesItem>
    handleSelectProduct?: (item: IPalletDetailes) => void
    item: IPalletDetailes,
    deleteProduct: (palletNum: number, recId: number) => void
}
interface IRowElement {
    color?: string
}

const PalletDetailesItem = ({
     ProductDesc, 
     VarietyDesc, 
     SizeID, 
     packageHash, 
     PackID, 
     Quantity, 
     WeightBuy, 
     Pack_Name, 
     handleSelectProduct, 
     GeneralProductID,
     item,
     InvoiceBuy,
     InvoiceSale,
     deleteProduct,
     RecID,
     Pallet_Num
    }: IPalletDetailesItem) => {
    const goodTitle = `${SizeID.trim()} ${VarietyDesc.trim()}  ${ProductDesc.trim()}`
    const packType = packageHash ? packageHash[PackID]?.Pack_Type : 0
    const { color, component } = stylePackByID(packType)
    const handleDeleteProduct = () => {
        if(InvoiceBuy !== 0 || InvoiceSale !== 0) return
        deleteProduct(Pallet_Num, RecID)
    }
    const handleSelectForUpdateProduct = () => {
        if(!handleSelectProduct || InvoiceBuy !== 0) return
        handleSelectProduct(item)
    }
    return (
        <MainWrapper
        >
            <SideWrapper>
                <DropdownByIcon
                isSidePosition
                    icon={ <ProductImage
                        id={GeneralProductID}
                    />}
                    
                >
                <WrapperMenu>
                
                    <IconWrapperMenu
                        onClick={handleSelectForUpdateProduct}
                    >
                        <EditIcon />
                    </IconWrapperMenu>
                    {
                    
                        <IconWrapperMenu 
                            isPrimal
                            onClick={handleDeleteProduct}
                        >
                            <DeleteIcon />
                        </IconWrapperMenu>
                    }
                    {/* <IconWrapperMenu
                        onClick={handleClick}
                    >
                        <PrintIcon />
                    </IconWrapperMenu> */}

                </WrapperMenu>
                </DropdownByIcon> 
                    
            </SideWrapper>
           
            <RowItem>
                <RowElement >
                    {goodTitle}
                    <AppleIcon />
                </RowElement>
            </RowItem>
            <RowItem>
                <RowElement >
                    {WeightBuy}
                    <ScalesIcon />
                </RowElement>
                <RowElement >
                    {Quantity} - {Pack_Name?.trim()}
                    {component}
                </RowElement>
            </RowItem>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
position: relative;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 0.3em 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    color: #7D7DA4;
    i {
        color: #7ab0ab;
    }
`
const RowItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
`
const RowElement = styled.div<IRowElement>`
    display: flex;
    gap: 0.5em;
    i {
        color: ${props => props.color ? props.color : '#7ab0ab'}
    }
    @media screen and (max-width: 800px) {
        font-size: 0.9em;
    }
`
const SideWrapper = styled.div`
    position: absolute;
    top: 0.2em;
    left: 1em;
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #297F76;
    div {

        background: #fff;
    }
    cursor: pointer;
    transition: all 0.3s ease-in;
    :hover {
        color: #7ab0ab;
    }
`
const WrapperMenu = styled.div`
    padding: 0.1em;
    display: flex;
    gap: 0.5em;
`
const IconWrapperMenu = styled.div<{isPrimal?: boolean}>`
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border-radius: 50%;
    background-color: #fff;
    color: ${props => props.isPrimal ? 'tomato' : '#297F76'} ;
    
    cursor: pointer;
    
`
export default PalletDetailesItem