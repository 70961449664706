import React from 'react'
import styled from 'styled-components'
import { LangIcon } from '../icons/Icons'
import { langList } from '../artcoded/langs'
import { useCookies } from 'react-cookie';
import { useDetectOutsideClick } from '../hooks/useDetectOutsideClick'
import i18next from 'i18next'


const LangSwitcher = () => {
  const [cookies, setCookies] = useCookies(['i18next'])
  const [isOpenMenu, setIsOpenMenu] = React.useState(false)
  const ref = React.useRef(null)

  const handleCloseMenu = () => {
    setIsOpenMenu(false)
  }
  const handleOpenMenu = () => {
    setIsOpenMenu(true)
  }
  const handleSelectLang = (e: React.MouseEvent<HTMLElement>, code: string) => {
    e.stopPropagation()
    setCookies('i18next', code)
    i18next.changeLanguage(code)
    handleCloseMenu()
    // reload page
    window.location.reload()
  }
  useDetectOutsideClick(ref, handleCloseMenu)
  return (
    <MainWrapper
      ref={ref}
      onClick={handleOpenMenu}
    
    >
      <LangIcon />
      <MenuWrapper isOpen={isOpenMenu}>
        {
          langList.map((lang, index) => 
            <Item 
              key={index}
              onClick={(e) => handleSelectLang(e, lang.code)}
              disabled={lang.code === cookies.i18next}
            >
              {lang.name}
            </Item>
          )
        }
      </MenuWrapper>
    </MainWrapper>
  )
}

export default LangSwitcher

const MainWrapper = styled.div`
  position: relative;
  width: 3em;
  height: 3em;
  background: #fff;
  border-radius: 50%;
  display: flex;
  
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  /* transform: rotate(0); */
  cursor: pointer;
  i {
    font-size: 1.5em;
    color: #297F76;
  }
  :hover {
    i {
      transform: scale(1.1);
    }
  }
`
const MenuWrapper = styled.div<{isOpen: boolean}>`
  position: absolute;
  background: #fff;
  left: 0;
  top: 3.2em;
  padding: 0.5em;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  z-index: 1;
  flex-direction: column;
  gap: 0.1em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`
const Item = styled.button<{disabled: boolean}>`
  padding: 0.5em;
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
  outline: none;
  border: none;
  font-size: 1.05em;
  cursor: pointer;
  background: #fff;
  border-radius: 10px;
  color: #297F76;
  :disabled {
    color: #ccc;
    cursor: not-allowed;
    :hover {
      background: #fff;
    
    }
  }
  :hover {
    background: #f5f5f5;
  }
`