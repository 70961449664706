import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_ORDER_MONOG, GET_GOODS_LIST } from '../../../../../modules/API/settings'
import { LOAD, LOAD_END, LOGOUT } from '../../../../../../store/actions/actionsTypes'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import ClientList from './inc/searchinglist'
import GoodsList, { TextWrapper } from './goods/goodsList'
import ModalRemark from './remark/modalRemark'
import FilterGoodsList from './filterGoods/filterGoodsList'
import { format } from "date-fns"
import DatePicker from "react-datepicker"
import CloseModalAzmanot from './buttons/closeModalAzmanot'
import SaveAzmanotBtn from './buttons/saveAzmanotBtn'
import './modalAzmanot.scss'
import styled from 'styled-components'
import { useBeforeunload } from 'react-beforeunload'
import PresetModal from './presets/presetModal'
import FloatContainer from './sideFloatMenu/floatContainer'
import { vibrationFunc } from '../../../../../modules/vibrationModule'
import { SelectPanelWrapper } from '../azmanotmain/SelectBar'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { BasketIcon, CloseIcon, SpeedIcon } from '../../../../../generic/icons/Icons'
import { statusConverter } from '../../../../../generic/artcoded/statusAzmanot'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import { Link, useHistory } from 'react-router-dom'
import { Orders } from '../../../../../modules/API/Orders/orders'
import { LINKS } from '../../../../../Routs/config'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import CustomersOrdered from './CustomersOrdered'

export const MainSection = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    background: #e9f0f0;
    padding: 0.5em;
    width: 100%;
    height: 100%;
    z-index: 2;
    @media screen and (max-width: 450px) {
        padding: 0;
    }
`
export const InnerWrapper = styled.div`
    position: relative;
    max-width: 1000px;
    height: 100%;
    background: #e9f3f2;
    margin: 0 auto;
    padding: 0 0.5em;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    gap: 0.1em;
    overflow: hidden;
    border-radius: 30px;
    /* -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); */
    @media screen and (max-width: 450px) {
        padding: 1em 0.5em 0 0.5em;
    }
`
const SelectPanel = styled(SelectPanelWrapper)`
    /* -webkit-box-shadow: none; 
    box-shadow: none; */
    flex-direction: row-reverse;
`
const DetalisPanel = styled(SelectPanelWrapper)`
    position: relative;
    max-width: 90%;
    border-radius: 0 0 30px 30px;
    
`
export const AkpacaContainer = styled.div`
    position: absolute;
    right: -1em;
    bottom: -0.5em;
    min-width: 2em;
    min-height: 2em;
    border-radius: 50%;
    background: #fdfdfd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
`
export const SearchWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
const ListContainer = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    max-height: 100px;
    overflow: hidden;
`
const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 0.5em;
    overflow-y: hidden;
    transform: translateY(-0.5em);

`
export const DateWrapper = styled.div`
    max-width: 110px;
    span {
        display: none;
    }
  .datePicker {
    height: 3.2em;
    border: none; 
    padding: 0.5em 1em; 
    text-align: right;
    border-radius: 30px;
    background: #eff3f4;
    font-size: 1.1em;
    max-width: 110px;
    color: #838484;
    outline: none;
  }
`
export const ControlPanelWrapper = styled.div`
    /* position: absolute;
    bottom: 0; */
    /* padding: 0 0.2em; */
    /* background: #80b3ae; */
    border-radius: 30px;
    width: 100%;
    min-height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
`
const Button = styled.button`
    position: relative;
    z-index: 3;
    background: none;
    outline: none;
    border: none;
    background: #eff3f4;
    border-radius: 50%;
    cursor: pointer;
    color: #297f76;
    min-width: 4em;
    height: 4em;

`
const TotalOrderedContainer = styled.div`
    position: absolute;
    width: 2em;
    height: 2em;
    top: -0.5em;
    right: -0.5em;
    background: #80e081;
    border-radius: 50%;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
`
const DeleteBtn = styled(Button)`
    border: none;
    color: #5a5a5a;
    height: 100%;
    width: 2em;
    margin-left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    :focus, :active{
        outline: none;
    }
`


export default function ModalAzmanot({ packegeList, fileterGoods, setFilterGoods,
    fileterGoodsDefault, setFilterGoodsDefault, getMlai, getAzmanot,
    getMhir, azmanot, filterGoodName, setFilterGoodName, setShowCreateAzmana, getPresetBrod }) 
{

    const clientListDefault = useSelector(token => token.defaults.clientList.list)
    const dayJump = useSelector(item => item.defaults?.defaults?.filter(elem => elem.Int_ID === 338)[0])
    const brodDate = new Date().setDate(new Date().getDate() + (dayJump?.Num_Value || 0))
    const [isLoadPrice, setIsLoadPrice] = useState(false)
    const [presetWindow, setPresetWindow] = useState(false);
    const [orderTotal, setOrderTotal] = useState(0);
    const [blockSave, setBlockSave] = useState(false);
    const [modal, setModal] = useContext(ContextModal);
    const [customerId, setCustomerId] = useState('');
    const [generalRemark, setGeneralRemark] = useState('');
    const [jumping, setJumping] = useState(false);
    const [product, setProduct] = useState([]);
    const [productDefault, setProductDefault] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState();
    const [statusFilter, setStatisFilter] = useState(true);
    const [filteredExist, setFilteredExist] = useState([]);
    const [addictinalDay, setAddictionalDay] = useState('0');
    const [input, setInput] = useState('')
    const [clientList, setClientList] = useState(clientListDefault);
    const [imputGoods, setInputGoods] = useState('');
    const [goodsList, setGoodsList] = useState([]);
    const [goodsListDefault, setGoodsListDefault] = useState([]);
    const goodsListTemp = goodsListDefault;
    const [showSearchGoods, setShowSearchGoods] = useState(false);
    const [showGoodsList, setShowGoodsList] = useState(true);
    const [showSearchClients, setShowSearchClients] = useState(false)
    const [startDate, setStartDate] = useState(brodDate);
    const [allowCreateOrder, setAllowCreateOrder] = useState({
        isAllow: false,
        expiredTime: ''
    })
   
    useEffect(() => {
        const find = product.some(e => {
            return e.Packs > 0
        })
        setSelectStatus(find)
    }, [product])

   
    const [filterGoodsValue, setFilterGoodsValue] = useState(null)
    const [selectStatus, setSelectStatus] = useState(false)
    const [filterToggleStatus, setFilterToggleStatus] = useState(false)
    const [filteredProduct, setFilteredProduct] = useState([])
    const remarkButtonRef = useRef(null)
    const [showRemark, setShowRemark] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState();
    //338
    const axios = require('axios')
    const CancelToken = axios.CancelToken.source();
    const defaults = useSelector(e => e.defaults?.defaults);
    const getToken = useSelector(token => token.mainReducer.token);
    const getUser = useSelector(token => token.mainReducer.user);
    const getRights = useSelector(rights => rights.mainReducer.user.rights);
    const isEditPrice = getRights.some(e => e === 'OFFICE' || e === 'SELLER' || e === 'ADMIN');
    const getTimeBrod = selectBrodMigdal(defaults, 342, 'Time_Value');
    const isEditArizot = selectBrodMigdal(defaults, 584, 'Logical_Value')
    const dateBlocker = selectBrodMigdal(defaults, 551, 'Logical_Value');
    // const neededStatus = selectBrodMigdal(defaults, 549, 'Logical_Value');
    const neededStatus = useMemo(() => {
        if(!selectedCustomer) return false
        return !!selectedCustomer.NeedGrowthGroup
        
    }, [selectedCustomer])
    const data = format(startDate, "yyyyMMdd");
    const dispatch = useDispatch()
    const history = useHistory();

    // CHECKER DATE AND TIME
    const [week, setWeek] = useState([])
    const [handleTime, setHandleTime] = useState('')
    const currentTime = format(new Date(), "HHmm00");
    const currentDate = format(new Date(), "yyyyMMdd"); // HHmm00
    const chosedDay = format(startDate, "EEEE")

    const passDay = (Object.entries(week).filter(e => { return e.includes(chosedDay) })[0])
        ? (Object.entries(week).filter(e => { return e.includes(chosedDay) })[0])[1]
        : []
        
    let dateToggle = jumping ? data : data

    const {
        data: dataCustomersOrdered, 
        isLoading: isLoadingCustomersOrdered
    } = useDataFetcher(
        Orders.getExistOrdersForClients, getRights.includes("SELLER") ? dateToggle : ''
    )

    // !check date validation from week array. set day + 1 if current date is close. repeat if next day close as well
    const pushDate = (startDate) => {
        if (Object.values(week).some(item => item)) {
            const date = new Date(Number(startDate));
            if (passDay.length < 1 || passDay) {
                return setStartDate(date)
            }
            else if (!passDay && input !== '') {
                date.setDate(date.getDate() + 1);
                setStartDate(date);
            }
        }
    }

    useEffect(() => {
        pushDate(startDate);
    }, [data, customerId])

    // reload warning !
    useBeforeunload((event) => {
        if (product.length === 0 || generalRemark === '') {
            event.preventDefault();
        }
    });

    useEffect(() => {
        const filtered = product.filter(item => {
            return item.GroupID === +filterGoodsValue
        })
        setFilteredProduct(filtered)
    }, [filterGoodsValue])

    useEffect(() => {
        const filtered = product.filter(item => {
            return item.GroupID === +filterGoodsValue
        })
        setFilteredProduct(filtered)
    }, [product])

    // add amlaca in each element of product list
    const setRecomenInArr = (arr, recArr) => {
        for (let i in arr) {
            for (let j in recArr) {
                if (arr[i].ProductID === recArr[j].ProductID && arr[i].VarietyID === recArr[j].VarietyID) {
                    arr[i].RecommendQty = recArr[j]
                }
            }
        }
        return arr
    }

    const getGoodPrice = async (prodID, varietyID, sizeID, packID, id, item) => {
        setIsLoadPrice(true)
        try {
            let res = await Orders.getGoodPrice(dateToggle, customerId, prodID, varietyID, sizeID, packID);
            let tempDef = [...goodsListDefault]
            let editedDef = tempDef.map((item) => {
                if(item.id === id) {
                    item.Price = res[0].CustPrice
                    item.MinPrice = res[0].MinimumPrice
                    item.isPriced = true
                }
                return item
            })
            setGoodsListDefault(editedDef)
            let temp = [...goodsList]
            let edited = temp.map((item) => {
                if(item.id === id) {
                    item.Price = res[0].CustPrice
                    item.MinPrice = res[0].MinimumPrice
                    item.isPriced = true
                }
                return item
            })
            setGoodsList([...edited])
        } catch (err) {} 
        finally {
            setIsLoadPrice(false)
        }
    }
    
    const getGoodsList = async (GET_GOODS_LIST, CustomerID, data) => {
        dispatch({ type: LOAD });
        // const currentDate = new Date();
        setShowSearchGoods(false)
        if(data) {
            setSelectedCustomer(data)
        }
        try {
            const res = await axios.get(GET_GOODS_LIST + CustomerID, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            },
                {
                    cancelToken: CancelToken.token
                })
            setShowSearchGoods(true);
            let newArr = addIDForTheList(setRecomenInArr(res.data.recommendations, res.data.recomWithQtyBySales));
            if (neededStatus || !!selectedCustomer?.NeedGrowthGroup || !!data?.NeedGrowthGroup) {
                setGoodsList([])
                setGoodsListDefault(newArr)
                setProduct([])
                setSelectStatus(false);
            } else {
                setGoodsList(newArr)
                setGoodsListDefault(newArr)
                setProduct([])
                setSelectStatus(false)
            }
            setAddictionalDay(res.data.addNumberOfDaysToDeliveryDate);

        } catch (err) {
            setModal({
                open: true,
                value: err.response?.data?.message,
                mood: true
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        } finally {
            dispatch({ type: LOAD_END });
        }
    }

    const createLog = async (data) => {
        try {
             await axios({
                method: 'post',
                url: CREATE_ORDER_MONOG,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    ...data
                }
            })
        } catch (err) {
            console.log(err.response)
        }
    }

    const calcTimeFunc = (time, format = "ms") => {
        const msPerHour = 3600000;
        const msPerMin = 60000;
        let hoursToMS = +time.substring(0, 2) * msPerHour;
        let minsToMS = +time.substring(3, 5) * msPerMin
        return format === 'ms' ? hoursToMS + minsToMS : hoursToMS + minsToMS
    }

    const checkTimeForSave = () => {
        const expiredTime = `${handleTime === '000000' ?
            getTimeBrod?.substring(0, 2) + ':' + getTimeBrod?.substring(2, 4) :
            handleTime?.substring(0, 2) + ':' + handleTime?.substring(2, 4)}`

        const timeExp = calcTimeFunc(expiredTime) / 60000;
        const timeCur = calcTimeFunc(currentTime) / 60000;
        
        const isAllowToSave = getRights.includes("OFFICE") 
                            || (handleTime === "000000"
                            ? currentTime < getTimeBrod || (+addictinalDay === 0 ? data - currentDate >= 1 : data - currentDate >= 2)
                            : currentTime < handleTime || (+addictinalDay === 0 ? data - currentDate >= 1 : data - currentDate >= 2))
        if (isAllowToSave) {
            setAllowCreateOrder({
                isAllow: true,
                expiredTime: expiredTime,
                timeLeft: timeExp - timeCur
            })
        }
        else {
            setAllowCreateOrder({
                isAllow: false,
                expiredTime: expiredTime,
                timeLeft: timeExp - timeCur
            })
        }
    }
    const postToWS = async (data) => {
        try {
            await Orders.passNewOrderToWS(data)
        } catch (err) {

        }
    }

    const createAzmana = async () => {
        let toggle = false
        setBlockSave(true);
        dispatch({ type: LOAD });

        const filtered = product.filter(item => {
            return item.GroupID === +filterGoodsValue
        }).map(e => {
            let {id, GroupID, ProdName, ...rest} = e
            return rest
        })

        setFilteredProduct(filtered)
        // delete empty products
        let withOutEmptyProd = product.filter(item => item.Packs > 0).map(e => {
            let {id, GroupID, ProdName, ...rest} = e
            return rest
        })

        //check in exist azmanot 
        const filterForPritim = azmanot?.some(item => {
            return (item.GroupDesc?.trim() === filterGoodName?.trim() || item.GroupDesc?.trim() === "") &&
                item.CustomerID === customerId &&
                item.Delivery_Date === dateToggle &&
                item.Type === 1
        })
        if (product.some(e => { return e.Packs > 0 })) {
            if (
                allowCreateOrder.isAllow ||
               ( handleTime === "000000"
                ? currentTime < getTimeBrod || (+addictinalDay === 0 ? data - currentDate >= 1 : data - currentDate >= 2)
                : currentTime < handleTime || (+addictinalDay === 0 ? data - currentDate >= 1 : data - currentDate >= 2))
            ) {
                if (passDay) {

                    // check brotMigdalStatus if they has pritim - true, check azmanot for current data\current customer\current filter
                    if (filterForPritim && (setAkpaca === 1)) {
                        dispatch({ type: LOAD_END });
                        setModal({
                            open: true,
                            value: 'הזמנה כבר קיימת',
                            mood: false
                        })
                        setTimeout(() => {
                            setModal({
                                ...modal,
                                open: false
                            })
                        }, 3000)
                        setBlockSave(false);
                        vibrationFunc('warning');
                    } else {
                        const data = {
                            "CustomerID": customerId,
                            "Remark": generalRemark,
                            "Jumping": setAkpaca,
                            "Delivery_Date": dateToggle,
                            "ProductGroup": neededStatus ? +filterGoodsValue : 0,
                            'Products': neededStatus ? filtered : withOutEmptyProd
                        }
                        try {
                            // let res = await Orders.createOrder(data)
                            let res = await Orders.createOrderGo(data)
                            setStatisFilter(true)
                            setModal({
                                open: true,
                                value: `ההזמנה ${res.OrderID} נוצרה בהצלחה`,
                                mood: true
                            })
                            setTimeout(() => {
                                setModal({
                                    ...modal,
                                    open: false
                                })
                            }, 3000)
                            const currDate = new Date().toISOString();
                            let curData = {
                                orderID: res?.OrderID,
                                user: getUser,
                                statusCode: 200,
                                payload: data,
                                requestType: "create",
                                date: {
                                    date: currDate,
                                    localDate: currentDate
                                },
                                history: [{
                                    date: currDate,
                                    StatusCode: 0,
                                    StatusDesc: statusConverter(0).status
                                }],
                                update: []
                            }
                            history.push(LINKS.orders.clientOrders.mainUrl);
                            getAzmanot()
                            await createLog(curData);
                            postToWS(res)
                            toggle = true
                            vibrationFunc();
                            setShowCreateAzmana(false)
                            setBlockSave(false);
                        } catch (err) {
                            setBlockSave(false);
                            dispatch({ type: LOAD_END });
                            vibrationFunc('warning');
                            let curData = {
                                orderID: 'error',
                                user: getUser,
                                statusCode: err?.response?.status || 0,
                                payload: err?.response?.data || '',
                                requestType: "create",
                                date: {
                                    date: new Date(),
                                }
                            }
                            if (!toggle) {
                                createLog(curData);
                            }
                            if (err?.response?.data) {
                                setModal({
                                    open: true,
                                    value: err?.response?.data?.message,
                                    mood: false
                                })
                                setTimeout(() => {
                                    setModal({
                                        ...modal,
                                        open: false
                                    })
                                }, 3000)
                            }
                        } finally {
                            dispatch({ type: LOAD_END });
                        }
                    }
                } else {
                    setBlockSave(false);
                    dispatch({ type: LOAD_END });
                    vibrationFunc('warning');
                    setModal({
                        open: true,
                        value: `לא ניתן להזמין ליום ${chosedDay === "Sunday" ? "ראשון"
                            : chosedDay === "Monday" ? "שני"
                                : chosedDay === "Tuesday" ? "שלישי"
                                    : chosedDay === "Wednesday" ? "רביעי"
                                        : chosedDay === "Thursday" ? "חמישי"
                                            : chosedDay === "Friday" ? "שישי"
                                                : "שבת"}`,
                        mood: false
                    })
                    setTimeout(() => {
                        setModal({
                            ...modal,
                            open: false
                        })
                    }, 3000)
                }
            } else {
                setBlockSave(false);
                dispatch({ type: LOAD_END });
                vibrationFunc('warning');
                setModal({
                    open: true,
                    value: `ניתן לבצע הזמנה עד - ${handleTime === '000000' ?
                        getTimeBrod.substring(0, 2) + ':' + getTimeBrod.substring(3, 4) :
                        handleTime.substring(0, 2) + ':' + handleTime.substring(3, 5)}`,
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
        } else {
            dispatch({ type: LOAD_END });
            setBlockSave(false);
            vibrationFunc('warning');
            setModal({
                open: true,
                value: 'הזמנה ריקה',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
        dispatch({type: LOAD_END})
    }

    useEffect(() => {
        if (clientListDefault && clientListDefault.length <= 1) {
            const item = clientListDefault[0]
            setSelectedCustomer(item)
            setCustomerId(item.CustomerID);
            setInput(item.Customer_Name.trim());
            getGoodsList(GET_GOODS_LIST, item.CustomerID, item);
            setHandleTime(item.To_PickupTime);
            setShowSearchGoods(true);
            setWeek({
                "Monday": item.Monday,
                "Tuesday": item.Tuesday,
                "Wednesday": item.Wednesday,
                "Thursday": item.Thursday,
                "Friday": item.Friday,
                "Saturday": item.Saturday,
                "Sunday": item.Sunday
            });
        }
        return () => {
            CancelToken.cancel("Component got unmounted");
        };
    }, [clientListDefault])
    // filter client list  (search bar)
    const updateInput = async (input) => {
        const filtered = clientListDefault?.filter(clientAz => {
            return clientAz.Customer_Name.trim().toLowerCase().includes(input.toLowerCase())
        })
        setInput(input);
        setClientList(filtered);
        setShowSearchClients(true)
    }
    const clietnSeatchBarToggle = document.getElementsByClassName("modal-azmanot-wrapper-inner__container-inc-wrapper-list")

    //Close modal on side click
    window.onclick = function (event) {
        if (event.target !== clietnSeatchBarToggle) {
            setShowSearchClients(false)
        }
    }

    const collectDataProduct = (product, setProduct, RecID, id, VarietyID, packs, packType, ProductID, SizeID, remark, mlai, priceGood, GroupID, Price, SalePrice, desc) => {
        // check ununiq id in array
        let tempArr = [...product]
        let found = tempArr.find((item) => {
            return item.id === id;
        })

        // if found, replace current element 
        if (found) {
            let indProd = tempArr.indexOf(found)
            tempArr.splice((indProd), 1)
        }
        setProduct([
            ...tempArr,
            {
                "id": id,
                "RecID": RecID,
                "VarietyID": VarietyID,
                "Packs": packs,
                "PackID": +packType,
                "ProductID": ProductID,
                "SizeID": SizeID,
                "Remark": remark,
                "Mlai": +mlai,
                "Price": +priceGood,
                "GroupID": GroupID || 0,
                "Update_Manual": Price === priceGood || (!Price && SalePrice === priceGood) ? false : true,
                "ProdName": desc
            }
        ])
    }

    const filterGoods = async (imputGoods) => {
        if (filterGoodsValue !== null || filterGoodsValue === '100' || !neededStatus) {
            
            if (!neededStatus) {
                if (statusFilter) {
                    const filtered = goodsListDefault.filter(goods => {
                        return `${goods.ProductDesc.trim()} ${goods.VarietyDesc.trim()} ${goods.SizeID.trim()}`
                            .toLowerCase().includes(imputGoods.toLowerCase())
                    })
                    setInputGoods(imputGoods);
                    setGoodsList(filtered);
                }
                if (!statusFilter) {
                    const filtered = filteredExist.filter(goods => {
                        return `${goods.ProductDesc.trim()} ${goods.VarietyDesc.trim()} ${goods.SizeID.trim()}`
                            .toLowerCase().includes(imputGoods.toLowerCase())
                    })
                    setInputGoods(imputGoods);
                    setGoodsList(filtered);
                }
            } else {
                if (statusFilter) {
                    const filtered = goodsListDefault.filter(goods => {
                        return `${goods.ProductDesc.trim()} ${goods.VarietyDesc.trim()} ${goods.SizeID.trim()}`
                            .toLowerCase().includes(imputGoods.toLowerCase())
                            && goods.GroupArray.includes(+filterGoodsValue)
                    })
                    setInputGoods(imputGoods);
                    setGoodsList(filtered);
                }
                else {
                    const filtered = filteredExist.filter(goods => {
                        return `${goods.ProductDesc.trim()} ${goods.VarietyDesc.trim()} ${goods.SizeID.trim()}`
                            .toLowerCase().includes(imputGoods.toLowerCase())
                            && goods.GroupArray.includes(+filterGoodsValue)
                    })
                    setInputGoods(imputGoods);
                    setGoodsList(filtered);
                }
            }
        }
        else {
            setModal({
                open: true,
                value: 'בחר קבוצה',
                mood: false
            })
            setTimeout(() => {
                setModal({
                    ...modal,
                    open: false
                })
            }, 3000)
        }
    }
    
    // check here for bug deleting prod
    const filterExist = () => {
        let newArr = []
        for (let i = 0; i < goodsList.length; i++) {
            for (let j = 0; j < product.length; j++) {
                if (product[j].id === goodsList[i].id && product[j].Packs > 0) {
                    newArr.push(goodsList[i])
                }
            }
        }
        if (newArr.length > 0 && statusFilter) {
            setGoodsList(newArr)
            setStatisFilter(false)
            setFilteredExist(newArr)
        }
    }

    const filterClear = async () => {
        setInputGoods('')
        if (!statusFilter && (filterGoodsValue !== null || filterGoodsValue === '100')) {
            setGoodsList(filteredExist)
        }
        // check filter status and set exist values
        if (statusFilter && !filterToggleStatus && (filterGoodsValue !== null || filterGoodsValue === '100' || !neededStatus)) {
            setGoodsList(goodsListDefault)
        }
        if (statusFilter && filterToggleStatus && (filterGoodsValue !== null || filterGoodsValue === '100' || !neededStatus)) {
            setGoodsList(fileterGoodsDefault)
        }
    }

    const filterClose = async () => {
        if (!statusFilter && !filterToggleStatus) {
            setGoodsList(goodsListDefault)
            setStatisFilter(true)
        }
        if (!statusFilter && filterToggleStatus) {
            setGoodsList(fileterGoodsDefault)
            setStatisFilter(true)
        }
    }

    // set new Arr, if product have packs - add key/value with packs type
    const filterExistDef = async () => {
        let newArr = [...goodsListDefault]
        for (let i = 0; i < goodsListDefault.length; i++) {
            for (let j = 0; j < product.length; j++) {
                if (product[j].id === goodsListDefault[i].id && product[j].Packs >= 0) {
                    newArr[i].Packs = product[j].Packs !==0 ? product[j].Packs: newArr[i].Packs
                    newArr[i].Price = product[j].Price !== 0 ? product[j].Price: newArr[i].Price
                    newArr[i].Mlai = product[j].Mlai !== 0 ? product[j].Mlai:  newArr[i].Mlai
                    newArr[i].PackID = product[j].PackID !== 0 ? product[j].PackID:  newArr[i].PackID
                }
            }
        }
        setGoodsListDefault(newArr)
    }

    //collect data from inputs and create new azmanot array
    const [dataLength, setDataLength] = useState(20)
    const loadAddData = () => {
        let newArr = [...goodsListDefault]
        if (goodsListDefault[dataLength]) {
            let currList = newArr.splice(0, dataLength);
            setGoodsList(currList)
        } else {
            setGoodsList(newArr)
        }
        setDataLength(prev => prev += 20)
    }

    // switch value of checkbox for selecting akpaca type
    const chengeAkpaca = () => {
        setJumping(!jumping)
    }
    useEffect(() => {
        setOrderTotal(0);
        checkTimeForSave();
    }, [customerId])

    useEffect(() => {
        filterExistDef();
    }, [product])

    let setAkpaca = jumping ? 2 : 1;
    let floatSideMenu = getPresetBrod ? <FloatContainer
        setPresetWindow={setPresetWindow}
        customerId={customerId}
        filterGoodsValue={filterGoodsValue}
        setShowRemark={setShowRemark}
        showRemark={showRemark}
        remarkButtonRef={remarkButtonRef}
        getPresetBrod={getPresetBrod}
        neededStatus={neededStatus}
    /> : ''
    let presetWindowToggle = presetWindow && getPresetBrod ? <PresetModal
        setPresetWindow={setPresetWindow}
        modal={modal}
        setModal={setModal}
        customerId={customerId}
        filterGoodName={filterGoodName}
        setGoodsList={setGoodsList}
        setGoodsListDefault={setGoodsListDefault}
        presetWindow={presetWindow}
        setProduct={setProduct}
        setProductDefault={setProductDefault}
        setShowGoodsList={setShowGoodsList}
        setFilterGoodsDefault={setFilterGoodsDefault}
        product={product}
        neededStatus={neededStatus}
        goodsListDefault={goodsListDefault}
        goodsListTemp={goodsListTemp}
        filterGoodsValue={filterGoodsValue}
        getPresetBrod={getPresetBrod}
    /> : '';
    // console.log(handleTime)
    const handleSelectFromRecommendClient = (item) => {
        const found = clientListDefault.find(e => e.CustomerID === item.CustomerID);
        if(!found) return
        setSelectedCustomer(found)
        console.log(found)
        setInput(found.Customer_Name.trim());
        setCustomerId(found.CustomerID);
        getGoodsList(GET_GOODS_LIST, found.CustomerID);
        setHandleTime(found.To_PickupTime);
        setShowSearchClients(false)
        setWeek({
            "Monday": found.Monday,
            "Tuesday": found.Tuesday,
            "Wednesday": found.Wednesday,
            "Thursday": found.Thursday,
            "Friday": found.Friday,
            "Saturday": found.Saturday,
            "Sunday": found.Sunday
        });
    }
    let checkButtoFilter = statusFilter ?
        <Button onClick={() => { filterExist() }}>
            <BasketIcon />
            <TotalOrderedContainer>{orderTotal}</TotalOrderedContainer>
        </Button>
        :
        <Button onClick={() => { filterClose() }}>
            <TotalOrderedContainer>{orderTotal}</TotalOrderedContainer>
            <CloseIcon />
        </Button>;
    let showBtnFilter = showSearchGoods ? checkButtoFilter : '';
    let showBtnClearInput = imputGoods.length > 0 ? <DeleteBtn onClick={() => { filterClear() }}><i className="fas fa-times"></i></DeleteBtn> : '';
    let goodsBar = showSearchGoods && showGoodsList ? <GoodsList
        presetWindow={presetWindow}
        goodsListDefault={goodsListDefault}
        setProductDefault={setProductDefault}
        getMlai={getMlai}
        getMhir={getMhir}
        goodsList={goodsList} setProduct={setProduct}
        product={product} chengeAkpaca={chengeAkpaca} packegeList={packegeList}
        filterClose={filterClose}
        setOrderTotal={setOrderTotal}
        orderTotal={orderTotal}
        collectDataProduct={collectDataProduct}
        filterGoodsValue={filterGoodsValue}
        dateForAmlaca={{ toDay: currentDate, choosen: data }}
        isEditArizot={isEditArizot}
        loadAddData={loadAddData}
        isEditPrice={isEditPrice}
        allowCreateOrder={allowCreateOrder}
        getGoodPrice={getGoodPrice}
        isLoadPrice={isLoadPrice}
    /> : '';
    let filterBarGoods = showSearchGoods ?
        <FilterGoodsList
            fileterGoods={fileterGoods}
            setFilterGoodsValue={setFilterGoodsValue}
            // filterProdTypes={filterProdTypes}
            goodsListDefault={goodsListDefault}
            fileterGoodsDefault={fileterGoodsDefault}
            setGoodsList={setGoodsList}
            setFilterToggleStatus={setFilterToggleStatus}
            setFilterGoodsDefault={setFilterGoodsDefault}
            setFilteredExist={setFilteredExist}
            setFilterGoodName={setFilterGoodName}
            setFilterGoods={setFilterGoods}
            selectStatus={selectStatus}
            setSelectStatus={setSelectStatus}
            neededStatus={neededStatus}
            loadAddData={loadAddData}
        /> :
        ''
    let azmanaAccept = product.length > 0 ? false : true
    let fieldClietns = showSearchClients ? "flex" : "none"
    return (
        <MainSection >
            {presetWindowToggle}
            {floatSideMenu}
            <InnerWrapper >
                <SelectPanel >
                    <SearchWrapper >
                        <SearchInput
                            input={input}
                            setInput={updateInput}
                            value={'בחר לקוח'}
                            fieldClietns={showSearchClients}
                            disabled={orderTotal !== 0 || clientList.length === 1}
                            iconHide={orderTotal !== 0 || clientList.length === 1}
                        />
                        <ClientList
                            setDeliveryDate={setDeliveryDate}
                            setProduct={setProduct}
                            clientList={clientList}
                            setInput={setInput}
                            setGoodsList={setGoodsList}
                            setGoodsListDefault={setGoodsListDefault}
                            setShowSearchGoods={setShowSearchGoods}
                            setCustomerId={setCustomerId}
                            getGoodsList={getGoodsList}
                            clientListDefault={clientListDefault}
                            setGeneralRemark={setGeneralRemark}
                            setWeek={setWeek}
                            setHandleTime={setHandleTime}
                            fieldClietns={fieldClietns}
                        />
                    </SearchWrapper>
                    <DateWrapper>
                        <DatePicker className='datePicker' disabled={!dateBlocker && !jumping} dateFormat="dd/MM/yy" selected={startDate} onChange={date => setStartDate(date)} />
                    </DateWrapper>
                </SelectPanel>
                {
                    dataCustomersOrdered && !showSearchGoods &&
                    <CustomersOrdered
                        handleClick={handleSelectFromRecommendClient}
                        data={dataCustomersOrdered}
                    />

                }
                <ContentWrapper style={{ display: showSearchGoods ? 'flex' : 'none' }}>
                    <DetalisPanel >
                        {
                            selectedCustomer?.Allow_Bouncing === 1 &&
                            <AkpacaContainer
                                onClick={() => { chengeAkpaca() }}
                                style={{ color: jumping ? 'tomato' : '#7d7b79' }}
                                title={'הקפצה'}
                            >
                                <SpeedIcon />
                            </AkpacaContainer>
                        }
                        <SearchInput input={imputGoods} setInput={filterGoods} />
                        {showBtnClearInput}
                        {showBtnFilter}
                        {filterBarGoods}
                    </DetalisPanel>
                    {
                        allowCreateOrder.isAllow && allowCreateOrder.timeLeft < 30 &&
                        <TextWrapper main>
                            'ניתן לבצע הזמנה עד - ' <b>{allowCreateOrder?.expiredTime}</b>
                        </TextWrapper>
                    }
                    {goodsBar}
                </ContentWrapper>
                <ModalRemark showRemark={showRemark} setShowRemark={setShowRemark} remarkButtonRef={remarkButtonRef}
                    generalRemark={generalRemark} setGeneralRemark={setGeneralRemark}
                />
                <ControlPanelWrapper>
                    <Link to={LINKS.orders.clientOrders.mainUrl}>
                        <CloseModalAzmanot closeModalAzmanot={() => { return }} />
                    </Link>
                    {
                        allowCreateOrder?.isAllow &&
                        <SaveAzmanotBtn blockSave={blockSave} createAzmana={createAzmana} azmanaAccept={azmanaAccept} />
                    }
                </ControlPanelWrapper>
            </InnerWrapper>
        </MainSection>
    );
}
