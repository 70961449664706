import React, {useState} from 'react'
import { IOrderRehesh } from '../../../../../../../modules/API/AzmanotRehesh/models/order'
import styled from 'styled-components'
import ProductImage from '../../../../queries/TeudotDetails/Components/ProductImage'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import EditProductItem from '../EditProductItem'
import { CountsAddData } from '../../types/countsAddData'
import AddGrower from '../AddGrower'
import { IGrowerItem } from '../../../../../../../modules/API/Defaults/models/growers'

type Props = {
  item: CountsAddData
  saveFunc: (item: IOrderRehesh) => void,
  calculationMethod: number
  growerList: IGrowerItem[]
}

const ModalEditProduct = ({
  item,
  calculationMethod,
  saveFunc,
  growerList
}: Props) => {
  const [t] = useTranslation('buttons')
  const [selectedItem, setSelectedItem] = useState<CountsAddData>(item)
  const [growerID, setGrowerID] = useState<number>(0)
  const [growerQty, setGrowerQty] = useState<number>(0)
  const [isOpenAddGrowerMenu, setIsOpenAddGrowerMenu] = useState<boolean>(false)
  const handleControlGrowerMenu = () => {
    setIsOpenAddGrowerMenu(!isOpenAddGrowerMenu)
  }
  const handleAddGrowerLine = () => {
    if(growerID === 0 || growerQty === 0) return
    const {
      DetailsByGrowers, 
      MlaiPacks,
      MlaiPallets,
      MlaiWieght,
      OrdersPacks,
      OrdersPallets,
      OrdersWieght,
      ...rest
    } = selectedItem
    const newDetails = [...selectedItem.DetailsByGrowers, {
      ...rest,
      RecID: 0,
      LineType: 2,
      GrowerID: growerID,
      GrowerName: growerList.filter(item => item.GrowerID === growerID)[0].GrowerName,
      OrderPacks: calculationMethod === 1 ? growerQty : 0,
      Order_Pallets: calculationMethod === 2 ? growerQty : 0,
      OrderWeight: calculationMethod === 3 ? growerQty : 0,
      Status: 1
    }]
    const finalQty = newDetails.reduce((acc, item) => 
      acc + (
        calculationMethod === 1 ? item.OrderPacks :
        calculationMethod === 2 ? item.Order_Pallets :
        item.OrderWeight
      ), 0)
    setSelectedItem({
      ...selectedItem,
      OrderPacks: calculationMethod === 1 ? finalQty : selectedItem.OrderPacks,
      OrderWeight: calculationMethod === 3 ? finalQty : selectedItem.OrderWeight,
      Order_Pallets: calculationMethod === 2 ? finalQty : selectedItem.Order_Pallets,
      DetailsByGrowers: newDetails
    })
    setGrowerID(0)
    setGrowerQty(0)
    handleControlGrowerMenu()

  }
  const handleClick = () => {
    const {
      MlaiPacks,
      MlaiPallets,
      MlaiWieght,
      OrdersPacks,
      OrdersPallets,
      OrdersWieght,
    ...rest
    } = selectedItem
    saveFunc(rest)
  }
  const handleChangeQty = (qty: number, index: number) => {
    const newDetails = selectedItem.DetailsByGrowers.map((item, i) => {
      if(i === index) {
        return {
          ...item,
          OrderPacks: calculationMethod === 1 ? qty : item.OrderPacks,
          Order_Pallets: calculationMethod === 2 ? qty : item.Order_Pallets,
          OrderWeight: calculationMethod === 3 ? qty : item.OrderWeight,
          Status: 1
        }
      }
      return item
    })
    const finalQty = newDetails.reduce((acc, item) => 
      acc + (
        calculationMethod === 1 ? item.OrderPacks :
        calculationMethod === 2 ? item.Order_Pallets :
        item.OrderWeight
      ), 0)
    setSelectedItem({
      ...selectedItem,
      OrderPacks: calculationMethod === 1 ? finalQty : selectedItem.OrderPacks,
      OrderWeight: calculationMethod === 3 ? finalQty : selectedItem.OrderWeight,
      Order_Pallets: calculationMethod === 2 ? finalQty : selectedItem.Order_Pallets,
      DetailsByGrowers: newDetails
    })
  }
  return (
    <MainWrapper>
      <ImageWrapper>
        <ProductImage 
          id={item.GeneralProductID}
        />
      </ImageWrapper>
      <BodyWrapper>
        <EditProductItem 
          {...selectedItem}
          calculationMethod={calculationMethod}
          handleChangeQty={handleChangeQty}
        />
      </BodyWrapper>
      <AddGrower
        growerID={growerID}
        setGrowerID={setGrowerID}
        setQty={setGrowerQty}
        qty={growerQty}
        growerList={growerList}
        ProductID={item.ProductID}
        handleClose={handleControlGrowerMenu}
        handleSave={handleAddGrowerLine}
        isOpenAddGrowerMenu={isOpenAddGrowerMenu}
      />
      <ControlButton 
        lable={t('save')}
        handleClick={handleClick}
      />
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
`
const ImageWrapper = styled.div`
  /* background: red; */
`
const BodyWrapper = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default ModalEditProduct