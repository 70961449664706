
import { useSelector } from 'react-redux'
import './App.css';
import LoginPage from './components/loginPage/loginPage'
import WelcPage from './components/main/welcompage/welcompage'
import SecondScreen from './components/main/second/second';
import ModalTest from './components/modules/modal/testModal'
import LoadingBar from 'react-redux-loading-bar'
import NotFound from './components/modules/404'
import { ContextModal } from './components/modules/modal/modalContext'
import './components/variable/variable.scss'
import {
  Route,
  Switch,
  Redirect,
  Router
} from "react-router-dom"
import history from './history'
import { useEffect, useState } from 'react';
import RegistrationPage from './components/loginPage/RegistrationPage';
import InvitedMain from './components/main/second/mainbar/invite/InvitedMain';



function App() {
  
  const [modal, setModal] = useState({
    open: false,
    value: ""
  })
  const user = useSelector(user => user?.mainReducer?.user)
  
  useEffect(() => {
    if ('orientation' in window.screen && window.screen.orientation.lock) {
      window.screen.orientation.lock('portrait').catch(console.error);
    }
  }, []);
  return (
     <Router history={history}>
        <ContextModal.Provider value={[modal, setModal]} >
          {user ?
            <Switch>
                <Route path='/main' component={SecondScreen} />
                <Route path='/invite/:id' component={InvitedMain} />
                <Redirect to='/main/azmanot'/>
            </Switch>
            :
            <Switch>
                <Route path='/home' component={WelcPage} />
                <Route path='/invite/:id' component={InvitedMain} />
                <Route path='/login' component={LoginPage} />
                <Route path='/registration' component={RegistrationPage}/>
                <Route path='/' component={LoginPage} />
                <Redirect to='/login'/>
                <Route component={NotFound} />
            </Switch>
          }
          <LoadingBar direction="rtl" style={{ backgroundColor: '#80e081', height: '5px', position: 'fixed' }} />
          <ModalTest />
        </ContextModal.Provider>
     </Router>
    
  );
}

export default App;

