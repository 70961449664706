import React from 'react'
import styled from 'styled-components';
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList';
import PalletItem from '../create/PalletItem';
import { IPalletEdited } from '../types/palletItem';
import useTabsTeuda from '../generic/tabList';

type Props = {
  activeTab: string;
  handleTabClick: (type: string) => void;
  renderPalletList: IPalletEdited[];
  PropsForPalletItem: any;

}

const TabsBodyContent = ({
  activeTab,
  handleTabClick,
  renderPalletList,
  PropsForPalletItem,
}: Props) => {
  const {TAB_LIST} = useTabsTeuda();

  return (
    
    <Container>
      <TabContainer>
        {TAB_LIST.map((type, index) => (
          <Tab
            key={index}
            active={activeTab === type}
            onClick={() => handleTabClick(type)}
          >
            {type}
          </Tab>
        ))}
      </TabContainer>
      <TitlesList
        list={renderPalletList}
        uniqueKey={'Pallet_Num'}
        Element={PalletItem}
        noPadding={false}
        additionalData={{...PropsForPalletItem, activeTab}}
        borderLess
      />
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #eff3f4;
  border-radius: 30px 30px 0 0;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 8px 16px;
  color: #838484;
  width: 7em;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  ${(props) =>
    props.active
      ? `
                background: #fff;
                border-bottom: none;
                color: #7ab0ab;
            `
      : `
            
            `}
`;
export default TabsBodyContent