import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { GET_PALLETS } from "../../../../modules/API/settings";
import { format } from "date-fns";
import axios from "axios";
import styled from "styled-components";
import { TagContainer } from "../arizot/arizotMain";
import { ContentWrapper, Decorator, MainWrapper, SectionMain } from "../secondbar/mainbar2";
import { getAzmanot } from "../../../../modules/requests/getAzmanot";
import { getPallets } from "../../../../modules/requests/getPallets";
import { LocationIcon, UserIcon, WarehouseIcon } from "../../../../generic/icons/Icons";
import { LikutModals } from "../../../../modules/Context/CefiNum";
import { SelectPanelWrapper } from "../secondbar/azmanotmain/SelectBar";
import Title from "../../../../generic/Title/Title";
import SearchInput from "../../../../generic/inputs/inputSearch";
import DatePickerModule from "../../../../generic/datePicker/datePicker";
import ModalContainer from "./modalsForLikut/ModalContainer";
import TitlesList from "../../../../generic/titlesRowItem/TitlesList";
import LikutExpressPalletSection from "./palletSection/LikutExpressPalletSection";
import LikutExpressAzmanotItem from "./azmanotSection/likutExpressAzmanotItem";
import { FilterIndicator } from "../trucks/TrucksMain";
import useWebSocket from "../../../../generic/hooks/useWebSocket";
import { Orders } from "../../../../modules/API/Orders/orders";
import { LINKS } from "../../../../Routs/config";
import { useTranslation } from "react-i18next";
import selectBrodMigdal from "../../../../generic/utils/selectBrodMigdal";
import useConfig from "./useConfig";
import useDataFetcher from "../knisot/hooks/useFetchData";
import { Defaults } from "../../../../modules/API/Defaults/defaults";
import DropdownByIcon from "../azmanotRehesh/Layouts/DropdownByIcon";
import SearchWarehouse from "../knisot/Create/Components/SearchWarehouse";
import { handleNotifications } from "../../../../generic/utils/handleNotifications";
import { LOAD, LOAD_END } from "../../../../../store/actions/actionsTypes";

export const ButtonWrapper = styled.div`
    width: 100%;
    height: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
`

export default function LikutExpress() {
    let { path } = useRouteMatch();
    const [t] = useTranslation(['likut']);
    const [isAddIdToDesc] = useConfig()
    const dispatch = useDispatch()
    const CancelToken = axios.CancelToken.source();
    const getToken = useSelector(token => token.mainReducer.token);
    const getRigths = useSelector(token => token.mainReducer.user?.rights);
    const defaults = useSelector((state) => state.defaults.defaults)
    const isAllowTopathWithActiveLikutStatus = selectBrodMigdal(defaults, 641, "Logical_Value")
    const checkFilterByWorkerRights = selectBrodMigdal(defaults, 410, "Logical_Value");
    const statusAzmanotToShow = useSelector(item => item.defaults?.defaults?.filter(i => i.Int_ID === 444)[0]);
    const isNeedToSortByKav = selectBrodMigdal(defaults, 649, "Logical_Value")
    const clientList = useSelector(e => e.defaults.clientList);
    const idForWorker = useSelector(token => token?.mainReducer?.user?.code_internal);
    const accName = useSelector(token => token?.mainReducer?.login)
    const [selectedDateStart, setSelectedDateStart] = useState(new Date())
    const [sortedByRamp, setSortedByRamp] = useState(false)
    const [tranzitID, setTranzitID] = useState(0)
    // azmanot list
    const [azmanotListStart, setAzmanotListStart] = useState([])
    const [azmanotList, setAzmanotList] = useState([])
    const [azmanotListDefault, setAzmanotListDefault] = useState([])
    const [azmanaNum, setAzmanaNum] = useState(null)
    // pallets list
    const [palletList, setPalletList] = useState([])
    const [palletListDefault, setPalletListDefault] = useState([])

    const [inputAzmanotSearch, setInputAzmanotSearch] = useState('')
    // resons
    // console.log(azmanaNum)
    const {socket, message} = useWebSocket('wss://my.agroline.co.il/ws/ws')
    // console.log(message)
    const isIgnoreDate = selectBrodMigdal(defaults, 634, "Logical_Value")
    
    const {
        data: warehouseList
    } = useDataFetcher(
        Defaults.getWarhouseList, "data"
    )

    useEffect(() => {
        let tempObj
        try {
            tempObj = JSON.parse(message)
        } catch {
            tempObj = null
        }
        if (!tempObj) return
        const found = azmanotListDefault.find(e => e.OrderID === tempObj?.OrderID)
        if (found) {
            let tempArr = [...azmanotList]
            let tempArrDef = [...azmanotListDefault]
            if(tempObj.Status === -1) {
                const foundCurrent = azmanotList.find(e => e.OrderID === tempObj?.OrderID);
                let indexOfItemDef = azmanotListDefault.indexOf(found);
                tempArrDef.splice(indexOfItemDef, 1)
                if(foundCurrent) {
                    let indexOfItem = azmanotList.indexOf(foundCurrent);
                    tempArr.splice(indexOfItem, 1)
                }
            } else {
                tempArr = azmanotList.map(e => {
                    if(e.OrderID === tempObj?.OrderID) {
                        e = tempObj
                    }
                    return e
                })
                tempArrDef = azmanotListDefault.map(e => {
                    if(e.OrderID === tempObj?.OrderID) {
                        e = tempObj
                    }
                    return e
                })
            }
            setAzmanotList(tempArr)
            setAzmanotListDefault(tempArrDef)
        } else {
            let checkInput = tempObj.OrderID.toString().includes(inputAzmanotSearch) ||
                tempObj?.Customer_Name.toLowerCase().includes(inputAzmanotSearch.toLowerCase())
            if(!checkFilterByWorkerRights || !getRigths.includes("WORKER") || tempObj.WorkerID === +idForWorker) {
                if(checkInput) {
                    setAzmanotList([tempObj, ...azmanotList])
                }
                setAzmanotListDefault([tempObj, ...azmanotListDefault])
            }
        }
    }, [message, socket])

    
    const [resonState, setResonState] = useState({
        resonTag: null,
        action: null,
        active: false,
        props: {}
    })

    const calcEndDay = () => {
        let date = new Date();
        let endDate = date.setDate(date.getDate() + 7);
        let final = format(endDate, "yyyyMMdd")
        return final
    }

    const sortByRamp = () => {
        if (!sortedByRamp) {
            const sorted = [...azmanotList].sort((a, b) => {
                return clientList?.hashTable[a.CustomerID]?.RampId - clientList?.hashTable[b.CustomerID]?.RampId
            })
            setAzmanotList(sorted)
            setSortedByRamp(true);
        } else {
            setAzmanotList(azmanotListDefault)
            setSortedByRamp(false);
        }
    }
    // set azmanot list before view
    useEffect(() => {

        if (selectedDateStart?.toString().length > 8) return

        const calcedDate = calcEndDay()
        const dateStart = isIgnoreDate ? "0" : selectedDateStart
        const dateEnd = isIgnoreDate ? "99999999" : calcedDate

        const fetchOrders = async () => {
            dispatch({type: LOAD})
            try {
                const res = await Orders.getOrdersGo(`DateFrom=${dateStart}&DateTo=${dateEnd}`, tranzitID)
                
                if(isNeedToSortByKav) {
                    setAzmanotListDefault(res);
                    setAzmanotList(res);
                }
                else {
                    filterAzmanotByBrod(res)
                }
                console.log(res)
            } catch (err) {
                
            } finally {
                dispatch({type: LOAD_END})
            }
        }
        fetchOrders()
        return () => {
            CancelToken.cancel("Component got unmounted");
        }
    }, [selectedDateStart, tranzitID])

    //set azmanot list for view

    const filterAzmanotByBrod = (state) => {
        const filtered = state.filter(item =>
            (checkFilterByWorkerRights && getRigths.includes('WORKER') ? item.WorkerID === +idForWorker : true) &&
            (statusAzmanotToShow?.Text_Value?.trim() ? statusAzmanotToShow.Text_Value?.includes(item?.Status?.toString()) : true)
        );
        filtered.sort((a, b) => a.PickupOrder - b.PickupOrder)
        setAzmanotListDefault(filtered);
        setAzmanotList(filtered);
    }

    useEffect(() => {
        getPallets(getToken, GET_PALLETS, setPalletList, setPalletListDefault);
        return () => {
            CancelToken.cancel("Component got unmounted");
        }
    }, [])

    const searchAzmana = (input) => {
        const filtered = azmanotListDefault.filter(e => {
            return e.Customer_Name.toLowerCase().includes(input.toLowerCase())
                || e.OrderID.toString().includes(input.toLowerCase())
        })
        setInputAzmanotSearch(input);
        setAzmanotList(filtered);
    }

    const confirmSelectOrder = async (OrderType, OrderID, pathFunc) => {
        if(checkFilterByWorkerRights) {
            pathFunc()
            return
        }
        const data = {
            "Status": 1,
            "WorkerID": +idForWorker || 0
        }
        try {
            await Orders.modifyOrder(OrderType, OrderID, data)
            pathFunc()
        } catch (err) {
            handleNotifications('קיימת הזמנה בסטטוס ליקוט', {type: 'warning'})
        } finally {

        }
    }
    const checkForActiveLikutAzmana = (Status, clientID, OrderType, OrderID, pathFunc, WorkerID, TZSort) => {
        
        const isAllowStatusToMoveLikut = (Status === 0 || Status === 2  || Status === 5 || Status === 7) 
        const isAllowToGet = (WorkerID === 0 || WorkerID === +idForWorker)        
        
        if (
            getRigths.includes("ADMIN") || 
            getRigths.includes("OFFICE") || 
            getRigths.includes("DEV") ||
            isAllowTopathWithActiveLikutStatus
        ) {
            pathFunc()
            return
        }


        if (!isAllowToGet) {
            handleNotifications('הזמנה שיכת לעובד אחר', {type: 'warning'})
        }

       
        let check = azmanotListDefault.find(e => e.Status === 1 && e.WorkerID === +idForWorker);
        
        if(check) {  

            if(!isAllowStatusToMoveLikut){
                pathFunc() 
            } else if (isAllowStatusToMoveLikut && check.CustomerID === clientID) {
                
                if(isNeedToSortByKav) {
                    if(TZSort === 0) {
                        confirmSelectOrder(OrderType, OrderID, pathFunc)
                    } else {

                        const findeOrderInPrevSort = azmanotListDefault.find(
                            order => 
                                (order.TZSort === TZSort - 1) 
                                && (order.Status === 0 || order.Status === 2 || order.Status === 5)
                        )

                        if(findeOrderInPrevSort) {
                            handleNotifications('יש הזמנה קודמת שלא סופקה', {type: 'warning'})
                        } else {
                            confirmSelectOrder(OrderType, OrderID, pathFunc)
                        }
                    }
                } else {
                    confirmSelectOrder(OrderType, OrderID, pathFunc)  
                }
            } else {
                handleNotifications('קיימת הזמנה בסטטוס ליקוט', {type: 'warning'})
                
            }
        } else {

            if(isAllowStatusToMoveLikut) {
                if(isNeedToSortByKav) {
                    if(TZSort === 0) {
                        confirmSelectOrder(OrderType, OrderID, pathFunc)
                    } else {

                        const findeOrderInPrevSort = azmanotListDefault.find(
                            order => 
                                (order.TZSort === TZSort - 1) 
                                && (order.Status === 0 || order.Status === 2 || order.Status === 5)
                        )

                        if(findeOrderInPrevSort) {
                            handleNotifications('יש הזמנה קודמת שלא סופקה', {type: 'warning'})
                        } else {
                            confirmSelectOrder(OrderType, OrderID, pathFunc)
                        }
                    }
                } else {
                    confirmSelectOrder(OrderType, OrderID, pathFunc)  
                }
            } else {
                pathFunc() 
            }
        }
    }
    const likutAzmanaListProps = {
        azmanaNum: azmanaNum,
        setAzmanaNum: setAzmanaNum,
        checkForActiveLikutAzmana: checkForActiveLikutAzmana,
        clientList: clientList,
        isAddIdToDesc: isAddIdToDesc
    }
   
    return (
        <SectionMain>
            <LikutModals.Provider value={[resonState, setResonState]} >
                <ModalContainer />
                    <Switch>
                        <Route exact path={path}>
                            <MainWrapper >
                                <Decorator />
                                <Title label={''} />
                                <SelectPanelWrapper >
                                    {
                                        isNeedToSortByKav && warehouseList ?
                                        <DropdownByIcon
                                            icon={<WarehouseIcon />}
                                            isSelected={!!tranzitID}
                                        >
                                        <Wrapper>

                                            <SearchWarehouse 
                                                list={warehouseList || []}
                                                setWarehouseID={setTranzitID}
                                                defaultID={tranzitID}
                                            />
                                        </Wrapper>
                                        </DropdownByIcon> : ''
                                    }
                                    <FilterIndicator >
                                        <ButtonWrapper onClick={() => {sortByRamp()}}>
                                            <LocationIcon />
                                        </ButtonWrapper>
                                    </FilterIndicator>
                                    <DatePickerModule
                                        withoutStyle
                                        ignorBrod
                                        disabled
                                        setSelectedDateStart={setSelectedDateStart}

                                    />
                                    
                                    <SearchInput value={t('likut_orders_search_placeholder')} input={inputAzmanotSearch} setInput={searchAzmana} />
                                    {/* <p style={{fontSize: '1.2em', color: 'rgba(0,0,0, 0.7)', paddingRight: '0.5em'}}>הזמנה שלי</p> */}
                                </SelectPanelWrapper>
                                <ContentWrapper >
                                    <TitlesList
                                        list={azmanotList}
                                        uniqueKey={'OrderID'}
                                        Element={LikutExpressAzmanotItem}
                                        additionalData={likutAzmanaListProps}
                                        
                                    />
                                </ContentWrapper>
                            </MainWrapper>
                        </Route>
                        <Route path={LINKS.likut.likutExperess.palletSection} render={() =>
                            <LikutExpressPalletSection
                                azmanaNum={azmanaNum}
                                palletList={palletList}
                                selectedDateStart={selectedDateStart}
                                setAzmanotListStart={setAzmanotListStart}
                                filterAzmanotByBrod={filterAzmanotByBrod}
                            />}
                        />
                
                    </Switch>
               
            </LikutModals.Provider>
        </SectionMain>
    )
}

const Wrapper = styled.div`
    width: 200px;
`