
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { statusConverter } from '../../../../../generic/artcoded/statusAzmanot'
import { DeleteIcon, EditIcon, UserIcon, CalendatDay } from '../../../../../generic/icons/Icons'
import { IMlaiTitlesItem } from '../../../../../modules/API/Packages/models/mlaiTypes'
import { convertToDate } from '../../../../../modules/convertDateString'
import { LINKS } from '../../../../../Routs/config'
import ModalDelete from '../../secondbar/modalAzmanot/modalDelete/modalDelete'



const ArizotAccountingItem = ({ Status, Remarks, ClientName, CreatedBy, PC_Date, PC_Num, deleteItem, selectRemark, id }) => {
  const [confermDelete, setConfermDelate] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const history = useHistory();
  const blockSwipe = Status !== 0;
  const { status, color } = statusConverter(Status)
  
  const handleDelete = (id) => {
    try {
      setIsDeleted(true)
      setTimeout(() => {
        deleteItem(PC_Num)
      }, 500)
    } catch (err) {
      setIsDeleted(false)

    }
  }
  const handleRemark = () => {
    const data = {
      title: ClientName?.trim(),
      body: Remarks?.trim(),
      id: id
    }
    selectRemark(data)
  }
  return (
    <MainWrapper isDeleted={isDeleted}>
      <SwipeableListItem
        swipeLeft={!blockSwipe ? {

          content: <ModalDelete
            background={"tomato"}
            callModal={!blockSwipe ? "? מחק" : ''}
            flex={"flex-end"}
            border
          />,

          action: () => {
            if (!blockSwipe) {
              setConfermDelate(!confermDelete)
            }
          },
        } : null}


        swipeRight={{
          content: <ModalDelete
            border
            callModal={Status === 0 ? "עדכון" : 'עיון'}
            background={'#7ab0ab'}

          />,
          action: () => {
            history.push(`${LINKS.arizot.arizaMlai.editByID}${PC_Num}`)
          }

        }}
        threshold={0.3}
      >
        <InnerContainer isShowDelete={confermDelete}>
          <ButtonWrapper 
            isActive={Remarks.trim()} 
            onClick={handleRemark}
          >
            <EditIcon />
          </ButtonWrapper>
          <ContentWrapper>
            <ContentItem isTitle>
              {ClientName?.trim().toLowerCase()}
            </ContentItem>
            <ContentItem>
              <ContentElement>
                {convertToDate(PC_Date)}
                <CalendatDay />
              </ContentElement>
              <ContentElement>
                {CreatedBy}
                <UserIcon />
              </ContentElement>
              <ContentElement>
                <StatusWrapper color={color}>
                  {status}
                </StatusWrapper>
              </ContentElement>
            </ContentItem>
          </ContentWrapper>
        </InnerContainer>
        <DeleteWrapper 
          isOpen={confermDelete}
          onClick={() => handleDelete(PC_Num)}
        >
          <DeleteIcon />
        </DeleteWrapper>

      </SwipeableListItem>
    </MainWrapper>
  )
}

export const MainWrapper = styled.div`
  width: 100%;
  border-radius: 30px;
  transition: 0.5s ease-out;
  transform: ${props => props.isDeleted ? 'translateX(-100%)' : 'translateX(0)'}
`
export const InnerContainer = styled.div`
    position: relative;
    height: 4.5em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #eff3f4;
    padding: 0.5em;
    
    border-radius: ${props => props.isShowDelete ? '30px 0 0 30px' : '30px'};
    cursor: grab;
    transition: 0.3 ease;
    :active {
      border-radius: 0;
    } 
`
export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
export const ContentItem = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  color: #297f76;
  gap: 1em;
  padding: 0 1em 0 0;
  /* font-weight: 600; */
  font-size: ${props => props.isTitle ? '1.1em' : '1em'};
`
export const ContentElement = styled.div`
  display: flex;
  gap: 0.5em;
`

export const StatusWrapper = styled.div`
  padding: 0.3em 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.color};
  border-radius: 30px;
`
export const DeleteWrapper = styled.div`
  width: 4em;
  height: 4em;
  border-radius: 0 30px 30px 0;
  display: ${props => props.isOpen ? 'flex' : 'none'} ;
  justify-content: center;
  align-items: center;
  color: #ffff;
  background: tomato;
  :active {
    border-radius: 0;
  }
`
const ButtonWrapper = styled.div`
    width: 4em;
    height: 100%;
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease-in;
    cursor: ${props => props.isActive ? 'pointer' : 'default'};
    i {
        display: ${props => props.isActive ? 'block' : 'none'};
        color: #7ab0ab

    }
    :hover i {
        color: tomato;
        transform: scale(1.2);
    }
`

export default ArizotAccountingItem